import * as adminActionType from "../../actions/action.admin/action.type";


const admin = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getalladmin_error: null,
    getalladmin_status: null,

    // admin

    create_admin: null,
    get_all_admin: [],
     all_admin: null,

     admin_by_id: [],
     admin_by_id_loading: false,
     admin_by_id_error: null,
     admin_enable_disable_status: null,
     update_admin_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //admin reducers type
        
    
        //create new admin
        case adminActionType.CREATE_ADMIN_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case adminActionType.CREATE_ADMIN_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_admin: action.result,
          });
        case adminActionType.CREATE_ADMIN_ERROR:
          return Object.assign({}, state, {
            getalladmin_status: action.status,
            getalladmin_error: action.error,
          });
         
          // all admins get
          case adminActionType.GET_ALL_ADMIN_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case adminActionType.GET_ALL_ADMIN_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_admin: action.result,
            });
          case adminActionType.GET_ALL_ADMIN_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get admins by id 
          case adminActionType.GET_ADMIN_BY_ID_REQ:
          return Object.assign({}, state, {
            admin_by_id: [],
            admin_by_id_loading: true,
            admin_by_id_error: null,
          });
        case adminActionType.GET_ADMIN_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            admin_by_id: action.payload,
            admin_by_id_loading: false,
            admin_by_id_error: null,
          });
        case adminActionType.GET_ADMIN_BY_ID_ERR:
          return Object.assign({}, state, {
            admin_by_id: [],
            admin_by_id_loading: false,
            admin_by_id_error: action.payload,
          });
          // update enable disable admin status 
          case adminActionType.PUT_ADMIN_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case adminActionType.PUT_ADMIN_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              admin_enable_disable_status:action.payload,
            });
          case adminActionType.PUT_ADMIN_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE admin DETAILS
        case adminActionType.PUT_ADMIN_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case adminActionType.PUT_ADMIN_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_admin_details: action.result,
          });
        case adminActionType.PUT_ADMIN_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default admin;
    
    
    