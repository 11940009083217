import { rootReducer } from "../root.reducer/root";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import * as api from "../../utils/apiRequest";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


// storing the redux state inside the store and dispacting 
const middleWare = [];
const persistConfig = {
  key: "root",
  storage,
  blacklist: [""],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
middleWare.push(
  thunk.withExtraArgument({
    api,
  })
);




const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

middleWare.push(loggerMiddleware);

export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middleWare))
  // applyMiddleware(...middleWare)
);
export const persistor = persistStore(store);
