import { getAction } from "connected-react-router";
import * as packageActionType from "../../actions/action.package/action.type";


const packages = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallpackage_error: null,
    getallpackage_status: null,

    // package

    create_package: null,
    get_all_package: [],
     all_package: null,

     package_by_id: [],
     package_by_id_loading: false,
     package_by_id_error: null,
     package_enable_disable_status: null,
     update_package_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //package reducers type
        
    
        //create new package
        case packageActionType.CREATE_PACKAGE_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case packageActionType.CREATE_PACKAGE_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_package: action.result,
          });
        case packageActionType.CREATE_PACKAGE_ERROR:
          return Object.assign({}, state, {
            getallpackage_status: action.status,
            getallpackage_error: action.error,
          });
         
          // all packages get
          case packageActionType.GET_ALL_PACKAGE_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case packageActionType.GET_ALL_PACKAGE_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_package: action.result,
            });
          case packageActionType.GET_ALL_PACKAGE_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get packages by id 
          case packageActionType.GET_PACKAGE_BY_ID_REQ:
          return Object.assign({}, state, {
            package_by_id: [],
            package_by_id_loading: true,
            package_by_id_error: null,
          });
        case packageActionType.GET_PACKAGE_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            package_by_id: action.payload,
            package_by_id_loading: false,
            package_by_id_error: null,
          });
        case packageActionType.GET_PACKAGE_BY_ID_ERR:
          return Object.assign({}, state, {
            package_by_id: [],
            package_by_id_loading: false,
            package_by_id_error: action.payload,
          });
          // update enable disable package status 
          case packageActionType.PUT_PACKAGE_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case packageActionType.PUT_PACKAGE_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              package_enable_disable_status:action.payload,
            });
          case packageActionType.PUT_PACKAGE_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE package DETAILS
        case packageActionType.PUT_PACKAGE_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case packageActionType.PUT_PACKAGE_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_package_details: action.result,
          });
        case packageActionType.PUT_PACKAGE_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default packages;
    
    
    