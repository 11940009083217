// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_TIMESLOT_REQUEST = "CREATE_TIMESLOT_REQUEST";
export const CREATE_TIMESLOT_SUCCESS = "CREATE_TIMESLOT_SUCCESS";
export const CREATE_TIMESLOT_ERROR = "CREATE_TIMESLOT_ERROR";

//GET ALL  TIMESLOT
export const GET_ALL_TIMESLOT_REQ = "GET_ALL_TIMESLOT_REQ";
export const GET_ALL_TIMESLOT_ERR = "GET_ALL_TIMESLOT_ERR";
export const GET_ALL_TIMESLOT_SUCC = "GET_ALL_TIMESLOT_SUCC";

//get TIMESLOT by id
export const GET_TIMESLOT_BY_ID_REQ = "GET_TIMESLOT_BY_ID_REQ";
export const GET_TIMESLOT_BY_ID_ERR = "GET_TIMESLOT_BY_ID_ERR";
export const GET_TIMESLOT_BY_ID_SUCCESS = "GET_TIMESLOT_BY_ID_SUCCESS";

// ENABLE_DISABLE TIMESLOT
export const PUT_TIMESLOT_STATUS_BY_ID_REQ = "PUT_TIMESLOT_STATUS_BY_ID_REQ";
export const PUT_TIMESLOT_STATUS_BY_ID_ERR = "PUT_TIMESLOT_STATUS_BY_ID_ERR";
export const PUT_TIMESLOT_STATUS_BY_ID_SUCC = "PUT_TIMESLOT_STATUS_BY_ID_SUCC";


//UPDATE TIMESLOT DETAILS
export const PUT_TIMESLOT_DETAILS_REQ = "PUT_TIMESLOT_DETAILS_REQ";
export const PUT_TIMESLOT_DETAILS_SUCC = "PUT_TIMESLOT_DETAILS_SUCC";
export const PUT_TIMESLOT_DETAILS_ERR = "PUT_TIMESLOT_DETAILS_ERR";


// //UPDATE TIMESLOT DETAILS
// export const PUT_CAMP_TIMESLOT_REQ = "PUT_CAMP_TIMESLOT_REQ";
// export const PUT_CAMP_TIMESLOT_SUCC = "PUT_CAMP_TIMESLOT_SUCC";
// export const PUT_CAMP_TIMESLOT_ERR = "PUT_CAMP_TIMESLOT_ERR";








