// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_ERROR = "CREATE_SERVICE_ERROR";

//GET ALL  SERVICE
export const GET_ALL_SERVICE_REQ = "GET_ALL_SERVICE_REQ";
export const GET_ALL_SERVICE_ERR = "GET_ALL_SERVICE_ERR";
export const GET_ALL_SERVICE_SUCC = "GET_ALL_SERVICE_SUCC";

//get SERVICE by id
export const GET_SERVICE_BY_ID_REQ = "GET_SERVICE_BY_ID_REQ";
export const GET_SERVICE_BY_ID_ERR = "GET_SERVICE_BY_ID_ERR";
export const GET_SERVICE_BY_ID_SUCCESS = "GET_SERVICE_BY_ID_SUCCESS";

// ENABLE_DISABLE SERVICE
export const PUT_SERVICE_STATUS_BY_ID_REQ = "PUT_SERVICE_STATUS_BY_ID_REQ";
export const PUT_SERVICE_STATUS_BY_ID_ERR = "PUT_SERVICE_STATUS_BY_ID_ERR";
export const PUT_SERVICE_STATUS_BY_ID_SUCC = "PUT_SERVICE_STATUS_BY_ID_SUCC";


//UPDATE SERVICE DETAILS
export const PUT_SERVICE_DETAILS_REQ = "PUT_SERVICE_DETAILS_REQ";
export const PUT_SERVICE_DETAILS_SUCC = "PUT_SERVICE_DETAILS_SUCC";
export const PUT_SERVICE_DETAILS_ERR = "PUT_SERVICE_DETAILS_ERR";


// //UPDATE SERVICE DETAILS
// export const PUT_CAMP_SERVICE_REQ = "PUT_CAMP_SERVICE_REQ";
// export const PUT_CAMP_SERVICE_SUCC = "PUT_CAMP_SERVICE_SUCC";
// export const PUT_CAMP_SERVICE_ERR = "PUT_CAMP_SERVICE_ERR";








