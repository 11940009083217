// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";

//GET ALL  CATEGORY
export const GET_ALL_CATEGORY_REQ = "GET_ALL_CATEGORY_REQ";
export const GET_ALL_CATEGORY_ERR = "GET_ALL_CATEGORY_ERR";
export const GET_ALL_CATEGORY_SUCC = "GET_ALL_CATEGORY_SUCC";

//get CATEGORY by id
export const GET_CATEGORY_BY_ID_REQ = "GET_CATEGORY_BY_ID_REQ";
export const GET_CATEGORY_BY_ID_ERR = "GET_CATEGORY_BY_ID_ERR";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";

// ENABLE_DISABLE CATEGORY
export const PUT_CATEGORY_STATUS_BY_ID_REQ = "PUT_CATEGORY_STATUS_BY_ID_REQ";
export const PUT_CATEGORY_STATUS_BY_ID_ERR = "PUT_CATEGORY_STATUS_BY_ID_ERR";
export const PUT_CATEGORY_STATUS_BY_ID_SUCC = "PUT_CATEGORY_STATUS_BY_ID_SUCC";


//UPDATE CATEGORY DETAILS
export const PUT_CATEGORY_DETAILS_REQ = "PUT_CATEGORY_DETAILS_REQ";
export const PUT_CATEGORY_DETAILS_SUCC = "PUT_CATEGORY_DETAILS_SUCC";
export const PUT_CATEGORY_DETAILS_ERR = "PUT_CATEGORY_DETAILS_ERR";


// //UPDATE CATEGORY DETAILS
// export const PUT_CAMP_CATEGORY_REQ = "PUT_CAMP_CATEGORY_REQ";
// export const PUT_CAMP_CATEGORY_SUCC = "PUT_CAMP_CATEGORY_SUCC";
// export const PUT_CAMP_CATEGORY_ERR = "PUT_CAMP_CATEGORY_ERR";








