// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_AREA_REQUEST = "CREATE_AREA_REQUEST";
export const CREATE_AREA_SUCCESS = "CREATE_AREA_SUCCESS";
export const CREATE_AREA_ERROR = "CREATE_AREA_ERROR";

//GET ALL  AREA
export const GET_ALL_AREA_REQ = "GET_ALL_AREA_REQ";
export const GET_ALL_AREA_ERR = "GET_ALL_AREA_ERR";
export const GET_ALL_AREA_SUCC = "GET_ALL_AREA_SUCC";

//get AREA by id
export const GET_AREA_BY_ID_REQ = "GET_AREA_BY_ID_REQ";
export const GET_AREA_BY_ID_ERR = "GET_AREA_BY_ID_ERR";
export const GET_AREA_BY_ID_SUCCESS = "GET_AREA_BY_ID_SUCCESS";

//get CITY-AREA by id
export const GET_CITY_AREA_BY_ID_REQ = "GET_CITY_AREA_BY_ID_REQ";
export const GET_CITY_AREA_BY_ID_ERR = "GET_CITY_AREA_BY_ID_ERR";
export const GET_CITY_AREA_BY_ID_SUCCESS = "GET_CITY_AREA_BY_ID_SUCCESS";

// ENABLE_DISABLE AREA
export const PUT_AREA_STATUS_BY_ID_REQ = "PUT_AREA_STATUS_BY_ID_REQ";
export const PUT_AREA_STATUS_BY_ID_ERR = "PUT_AREA_STATUS_BY_ID_ERR";
export const PUT_AREA_STATUS_BY_ID_SUCC = "PUT_AREA_STATUS_BY_ID_SUCC";


//UPDATE AREA DETAILS
export const PUT_AREA_DETAILS_REQ = "PUT_AREA_DETAILS_REQ";
export const PUT_AREA_DETAILS_SUCC = "PUT_AREA_DETAILS_SUCC";
export const PUT_AREA_DETAILS_ERR = "PUT_AREA_DETAILS_ERR";


// //UPDATE AREA DETAILS
// export const PUT_CAMP_AREA_REQ = "PUT_CAMP_AREA_REQ";
// export const PUT_CAMP_AREA_SUCC = "PUT_CAMP_AREA_SUCC";
// export const PUT_CAMP_AREA_ERR = "PUT_CAMP_AREA_ERR";








