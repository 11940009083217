// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_FAMILYMEMBER_REQUEST = "CREATE_FAMILYMEMBER_REQUEST";
export const CREATE_FAMILYMEMBER_SUCCESS = "CREATE_FAMILYMEMBER_SUCCESS";
export const CREATE_FAMILYMEMBER_ERROR = "CREATE_FAMILYMEMBER_ERROR";

export const CREATE_FAMILYMEMBER_MODAL_REQUEST = "CREATE_FAMILYMEMBER_MODAL_REQUEST";
export const CREATE_FAMILYMEMBER_MODAL_SUCCESS = "CREATE_FAMILYMEMBER_MODAL_SUCCESS";
export const CREATE_FAMILYMEMBER_MODAL_ERROR = "CREATE_FAMILYMEMBER_MODAL_ERROR";

//GET ALL  FAMILYMEMBER
export const GET_ALL_FAMILYMEMBER_REQ = "GET_ALL_FAMILYMEMBER_REQ";
export const GET_ALL_FAMILYMEMBER_ERR = "GET_ALL_FAMILYMEMBER_ERR";
export const GET_ALL_FAMILYMEMBER_SUCC = "GET_ALL_FAMILYMEMBER_SUCC";

//get FAMILYMEMBER by id
export const GET_FAMILYMEMBER_BY_ID_REQ = "GET_FAMILYMEMBER_BY_ID_REQ";
export const GET_FAMILYMEMBER_BY_ID_ERR = "GET_FAMILYMEMBER_BY_ID_ERR";
export const GET_FAMILYMEMBER_BY_ID_SUCCESS = "GET_FAMILYMEMBER_BY_ID_SUCCESS";

// ENABLE_DISABLE FAMILYMEMBER
export const PUT_FAMILYMEMBER_STATUS_BY_ID_REQ = "PUT_FAMILYMEMBER_STATUS_BY_ID_REQ";
export const PUT_FAMILYMEMBER_STATUS_BY_ID_ERR = "PUT_FAMILYMEMBER_STATUS_BY_ID_ERR";
export const PUT_FAMILYMEMBER_STATUS_BY_ID_SUCC = "PUT_FAMILYMEMBER_STATUS_BY_ID_SUCC";


//UPDATE FAMILYMEMBER DETAILS
export const PUT_FAMILYMEMBER_DETAILS_REQ = "PUT_FAMILYMEMBER_DETAILS_REQ";
export const PUT_FAMILYMEMBER_DETAILS_SUCC = "PUT_FAMILYMEMBER_DETAILS_SUCC";
export const PUT_FAMILYMEMBER_DETAILS_ERR = "PUT_FAMILYMEMBER_DETAILS_ERR";


// CREATE_FAMILYMEMBER_ADDRESS
export const CREATE_FAMILYMEMBER_ADDRESS_REQUEST = "CREATE_FAMILYMEMBER_ADDRESS_REQUEST";
export const CREATE_FAMILYMEMBER_ADDRESS_SUCCESS = "CREATE_FAMILYMEMBER_ADDRESS_SUCCESS";
export const CREATE_FAMILYMEMBER_ADDRESS_ERROR = "CREATE_FAMILYMEMBER_ADDRESS_ERROR";

//GET ALL  FAMILYMEMBER_ADDRESS
export const GET_ALL_FAMILYMEMBER_ADDRESS_REQ = "GET_ALL_FAMILYMEMBER_ADDRESS_REQ";
export const GET_ALL_FAMILYMEMBER_ADDRESS_ERR = "GET_ALL_FAMILYMEMBER_ADDRESS_ERR";
export const GET_ALL_FAMILYMEMBER_ADDRESS_SUCC = "GET_ALL_FAMILYMEMBER_ADDRESS_SUCC";










