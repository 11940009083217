// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_TECHNICIAN_REQUEST = "CREATE_TECHNICIAN_REQUEST";
export const CREATE_TECHNICIAN_SUCCESS = "CREATE_TECHNICIAN_SUCCESS";
export const CREATE_TECHNICIAN_ERROR = "CREATE_TECHNICIAN_ERROR";

//GET ALL  TECHNICIAN
export const GET_ALL_TECHNICIAN_REQ = "GET_ALL_TECHNICIAN_REQ";
export const GET_ALL_TECHNICIAN_ERR = "GET_ALL_TECHNICIAN_ERR";
export const GET_ALL_TECHNICIAN_SUCC = "GET_ALL_TECHNICIAN_SUCC";

//get TECHNICIAN by id
export const GET_TECHNICIAN_BY_ID_REQ = "GET_TECHNICIAN_BY_ID_REQ";
export const GET_TECHNICIAN_BY_ID_ERR = "GET_TECHNICIAN_BY_ID_ERR";
export const GET_TECHNICIAN_BY_ID_SUCCESS = "GET_TECHNICIAN_BY_ID_SUCCESS";

// ENABLE_DISABLE TECHNICIAN
export const PUT_TECHNICIAN_STATUS_BY_ID_REQ = "PUT_TECHNICIAN_STATUS_BY_ID_REQ";
export const PUT_TECHNICIAN_STATUS_BY_ID_ERR = "PUT_TECHNICIAN_STATUS_BY_ID_ERR";
export const PUT_TECHNICIAN_STATUS_BY_ID_SUCC = "PUT_TECHNICIAN_STATUS_BY_ID_SUCC";


//UPDATE TECHNICIAN DETAILS
export const PUT_TECHNICIAN_DETAILS_REQ = "PUT_TECHNICIAN_DETAILS_REQ";
export const PUT_TECHNICIAN_DETAILS_SUCC = "PUT_TECHNICIAN_DETAILS_SUCC";
export const PUT_TECHNICIAN_DETAILS_ERR = "PUT_TECHNICIAN_DETAILS_ERR";


// TECHNICIAN REPORTS BY TECHNICIAN ID
export const GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_REQ = "GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_REQ";
export const GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_SUCC = "GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_SUCC";
export const GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_ERR = "GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_ERR";

// TECHNICIAN REPORTS BY ORDER ID
export const GET_TECHNICIAN_REPORTS_BY_ORDER_ID_REQ = "GET_TECHNICIAN_REPORTS_BY_ORDER_ID_REQ";
export const GET_TECHNICIAN_REPORTS_BY_ORDER_ID_SUCC = "GET_TECHNICIAN_REPORTS_BY_ORDER_ID_SUCC";
export const GET_TECHNICIAN_REPORTS_BY_ORDER_ID_ERR = "GET_TECHNICIAN_REPORTS_BY_ORDER_ID_ERR";

// //UPDATE TECHNICIAN DETAILS
// export const PUT_CAMP_TECHNICIAN_REQ = "PUT_CAMP_TECHNICIAN_REQ";
// export const PUT_CAMP_TECHNICIAN_SUCC = "PUT_CAMP_TECHNICIAN_SUCC";
// export const PUT_CAMP_TECHNICIAN_ERR = "PUT_CAMP_TECHNICIAN_ERR";








