import { getAction } from "connected-react-router";
import * as faqActionType from "../../actions/action.faq/action.type";


const faq = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallfaq_error: null,
    getallfaq_status: null,

    // faq

    create_faq: null,
    get_all_faq: [],
     all_faq: null,

     faq_by_id: [],
     faq_by_id_loading: false,
     faq_by_id_error: null,
     faq_enable_disable_status: null,
     update_faq_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //faq reducers type
        
    
        //create new faq
        case faqActionType.CREATE_FAQ_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case faqActionType.CREATE_FAQ_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_faq: action.result,
          });
        case faqActionType.CREATE_FAQ_ERROR:
          return Object.assign({}, state, {
            getallfaq_status: action.status,
            getallfaq_error: action.error,
          });
         
          // all faqs get
          case faqActionType.GET_ALL_FAQ_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case faqActionType.GET_ALL_FAQ_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_faq: action.result,
            });
          case faqActionType.GET_ALL_FAQ_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get faqs by id 
          case faqActionType.GET_FAQ_BY_ID_REQ:
          return Object.assign({}, state, {
            faq_by_id: [],
            faq_by_id_loading: true,
            faq_by_id_error: null,
          });
        case faqActionType.GET_FAQ_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            faq_by_id: action.payload,
            faq_by_id_loading: false,
            faq_by_id_error: null,
          });
        case faqActionType.GET_FAQ_BY_ID_ERR:
          return Object.assign({}, state, {
            faq_by_id: [],
            faq_by_id_loading: false,
            faq_by_id_error: action.payload,
          });
          // update enable disable faq status 
          case faqActionType.PUT_FAQ_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case faqActionType.PUT_FAQ_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              faq_enable_disable_status:action.payload,
            });
          case faqActionType.PUT_FAQ_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE faq DETAILS
        case faqActionType.PUT_FAQ_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case faqActionType.PUT_FAQ_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_faq_details: action.result,
          });
        case faqActionType.PUT_FAQ_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default faq;
    
    
    