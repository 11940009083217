import * as miscellaneousActionType from "../../actions/action.miscellaneous/action.type";


const miscellaneous = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,


    // perKilometerCharge
    getallperKilometerCharge_error: null,
    getallperKilometerCharge_status: null,

    create_perKilometerCharge: null,
    perKilometerCharge: [],
    perKilometerCharge_loading: false,
    perKilometerCharge_error: null,
     update_perKilometerCharge_details: null,


     //penaltyAmountCancellingOrder

     getallpenaltyAmountCancellingOrder_error: null,
    getallpenaltyAmountCancellingOrder_status: null,

    create_penaltyAmountCancellingOrder: null,
    penaltyAmountCancellingOrder: [],
    penaltyAmountCancellingOrder_loading: false,
    penaltyAmountCancellingOrder_error: null,
     update_penaltyAmountCancellingOrder_details: null,


     // minimumAmountPlacingOrder
    getallminimumAmountPlacingOrder_error: null,
    getallminimumAmountPlacingOrder_status: null,

    create_minimumAmountPlacingOrder: null,
    minimumAmountPlacingOrder: [],
    minimumAmountPlacingOrder_loading: false,
    minimumAmountPlacingOrder_error: null,
     update_minimumAmountPlacingOrder_details: null,


     
    // lateCancellationPenalty
    getalllateCancellationPenalty_error: null,
    getalllateCancellationPenalty_status: null,

    create_lateCancellationPenalty: null,
    lateCancellationPenalty: [],
    lateCancellationPenalty_loading: false,
    lateCancellationPenalty_error: null,
     update_lateCancellationPenalty_details: null,


        // emergencyRequestCharge
    getallemergencyRequestCharge_error: null,
    getallemergencyRequestCharge_status: null,

    create_emergencyRequestCharge: null,
    emergencyRequestCharge: [],
    emergencyRequestCharge_loading: false,
    emergencyRequestCharge_error: null,
     update_emergencyRequestCharge_details: null,

      // codHardCopyReports
    getallcodHardCopyReports_error: null,
    getallcodHardCopyReports_status: null,

    create_codHardCopyReports: null,
    codHardCopyReports: [],
    codHardCopyReports_loading: false,
    codHardCopyReports_error: null,
     update_codHardCopyReports_details: null,

     // customerDeliveryCharges
    getallcustomerDeliveryCharges_error: null,
    getallcustomerDeliveryCharges_status: null,

    create_customerDeliveryCharges: null,
    customerDeliveryCharges: [],
    customerDeliveryCharges_loading: false,
    customerDeliveryCharges_error: null,
     update_customerDeliveryCharges_details: null,


    },
    action
) =>{

    switch (action.type) {
    
        //perKilometerCharge reducers type
        
    
        //create new perKilometerCharge
        case miscellaneousActionType.CREATE_PER_KILOMETER_CHARGE_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.CREATE_PER_KILOMETER_CHARGE_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_perKilometerCharge: action.result,
          });
        case miscellaneousActionType.CREATE_PER_KILOMETER_CHARGE_ERROR:
          return Object.assign({}, state, {
            getallperKilometerCharge_status: action.status,
            getallperKilometerCharge_error: action.error,
          });
         
          
          // get perKilometerCharge
          case miscellaneousActionType.GET_PER_KILOMETER_CHARGE_REQ:
          return Object.assign({}, state, {
            perKilometerCharge: [],
            perKilometerCharge_loading: true,
            perKilometerCharge_error: null,
          });
        case miscellaneousActionType.GET_PER_KILOMETER_CHARGE_SUCC:
          return Object.assign({}, state, {
            perKilometerCharge: action.payload,
            perKilometerCharge_loading: false,
            perKilometerCharge_error: null,
          });
        case miscellaneousActionType.GET_PER_KILOMETER_CHARGE_ERR:
          return Object.assign({}, state, {
            perKilometerCharge: [],
            perKilometerCharge_loading: false,
            perKilometerCharge_error: action.payload,
          });
         
         
              // UPDATE perKilometerCharge DETAILS
        case miscellaneousActionType.PUT_PER_KILOMETER_CHARGE_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.PUT_PER_KILOMETER_CHARGE_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_perKilometerCharge_details: action.result,
          });
        case miscellaneousActionType.PUT_PER_KILOMETER_CHARGE_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

       //----------------------------------------------//

          //penaltyAmountCancellingOrder reducers type
        
    
        //create new penaltyAmountCancellingOrder
        case miscellaneousActionType.CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_REQUEST:
            return Object.assign({}, state, {
              status: action.status,
            });
          case miscellaneousActionType.CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_SUCCESS:
            return Object.assign({}, state, {
              status: action.status,
              create_penaltyAmountCancellingOrder: action.result,
            });
          case miscellaneousActionType.CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_ERROR:
            return Object.assign({}, state, {
              getallpenaltyAmountCancellingOrder_status: action.status,
              getallpenaltyAmountCancellingOrder_error: action.error,
            });
           
            
            // get penaltyAmountCancellingOrder
            case miscellaneousActionType.GET_PENALTY_AMOUNT_CANCELLING_ORDER_REQ:
            return Object.assign({}, state, {
              penaltyAmountCancellingOrder: [],
              penaltyAmountCancellingOrder_loading: true,
              penaltyAmountCancellingOrder_error: null,
            });
          case miscellaneousActionType.GET_PENALTY_AMOUNT_CANCELLING_ORDER_SUCC:
            return Object.assign({}, state, {
              penaltyAmountCancellingOrder: action.payload,
              penaltyAmountCancellingOrder_loading: false,
              penaltyAmountCancellingOrder_error: null,
            });
          case miscellaneousActionType.GET_PENALTY_AMOUNT_CANCELLING_ORDER_ERR:
            return Object.assign({}, state, {
              penaltyAmountCancellingOrder: [],
              penaltyAmountCancellingOrder_loading: false,
              penaltyAmountCancellingOrder_error: action.payload,
            });
           
           
                // UPDATE penaltyAmountCancellingOrder DETAILS
          case miscellaneousActionType.PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case miscellaneousActionType.PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              update_penaltyAmountCancellingOrder_details: action.result,
            });
          case miscellaneousActionType.PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
        //-----------------------------------------------//



         //minimumAmountPlacingOrder reducers type
        
    
        //create new minimumAmountPlacingOrder
        case miscellaneousActionType.CREATE_MINIMUM_AMOUNT_PLACING_ORDER_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.CREATE_MINIMUM_AMOUNT_PLACING_ORDER_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_minimumAmountPlacingOrder: action.result,
          });
        case miscellaneousActionType.CREATE_MINIMUM_AMOUNT_PLACING_ORDER_ERROR:
          return Object.assign({}, state, {
            getallminimumAmountPlacingOrder_status: action.status,
            getallminimumAmountPlacingOrder_error: action.error,
          });
         
          
          // get minimumAmountPlacingOrder
          case miscellaneousActionType.GET_MINIMUM_AMOUNT_PLACING_ORDER_REQ:
          return Object.assign({}, state, {
            minimumAmountPlacingOrder: [],
            minimumAmountPlacingOrder_loading: true,
            minimumAmountPlacingOrder_error: null,
          });
        case miscellaneousActionType.GET_MINIMUM_AMOUNT_PLACING_ORDER_SUCC:
          return Object.assign({}, state, {
            minimumAmountPlacingOrder: action.payload,
            minimumAmountPlacingOrder_loading: false,
            minimumAmountPlacingOrder_error: null,
          });
        case miscellaneousActionType.GET_MINIMUM_AMOUNT_PLACING_ORDER_ERR:
          return Object.assign({}, state, {
            minimumAmountPlacingOrder: [],
            minimumAmountPlacingOrder_loading: false,
            minimumAmountPlacingOrder_error: action.payload,
          });
         
         
              // UPDATE minimumAmountPlacingOrder DETAILS
        case miscellaneousActionType.PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_minimumAmountPlacingOrder_details: action.result,
          });
        case miscellaneousActionType.PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

       //----------------------------------------------//

        //lateCancellationPenalty reducers type
        
    
        //create new lateCancellationPenalty
        case miscellaneousActionType.CREATE_LATE_CANCELLATION_PENALTY_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.CREATE_LATE_CANCELLATION_PENALTY_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_lateCancellationPenalty: action.result,
          });
        case miscellaneousActionType.CREATE_LATE_CANCELLATION_PENALTY_ERROR:
          return Object.assign({}, state, {
            getalllateCancellationPenalty_status: action.status,
            getalllateCancellationPenalty_error: action.error,
          });
         
          
          // get lateCancellationPenalty
          case miscellaneousActionType.GET_LATE_CANCELLATION_PENALTY_REQ:
          return Object.assign({}, state, {
            lateCancellationPenalty: [],
            lateCancellationPenalty_loading: true,
            lateCancellationPenalty_error: null,
          });
        case miscellaneousActionType.GET_LATE_CANCELLATION_PENALTY_SUCC:
          return Object.assign({}, state, {
            lateCancellationPenalty: action.payload,
            lateCancellationPenalty_loading: false,
            lateCancellationPenalty_error: null,
          });
        case miscellaneousActionType.GET_LATE_CANCELLATION_PENALTY_ERR:
          return Object.assign({}, state, {
            lateCancellationPenalty: [],
            lateCancellationPenalty_loading: false,
            lateCancellationPenalty_error: action.payload,
          });
         
         
              // UPDATE lateCancellationPenalty DETAILS
        case miscellaneousActionType.PUT_LATE_CANCELLATION_PENALTY_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.PUT_LATE_CANCELLATION_PENALTY_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_lateCancellationPenalty_details: action.result,
          });
        case miscellaneousActionType.PUT_LATE_CANCELLATION_PENALTY_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

       //----------------------------------------------//

       //emergencyRequestCharge reducers type
        
    
        //create new emergencyRequestCharge
        case miscellaneousActionType.CREATE_EMERGENCY_REQUEST_CHARGE_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.CREATE_EMERGENCY_REQUEST_CHARGE_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_emergencyRequestCharge: action.result,
          });
        case miscellaneousActionType.CREATE_EMERGENCY_REQUEST_CHARGE_ERROR:
          return Object.assign({}, state, {
            getallemergencyRequestCharge_status: action.status,
            getallemergencyRequestCharge_error: action.error,
          });
         
          
          // get emergencyRequestCharge
          case miscellaneousActionType.GET_EMERGENCY_REQUEST_CHARGE_REQ:
          return Object.assign({}, state, {
            emergencyRequestCharge: [],
            emergencyRequestCharge_loading: true,
            emergencyRequestCharge_error: null,
          });
        case miscellaneousActionType.GET_EMERGENCY_REQUEST_CHARGE_SUCC:
          return Object.assign({}, state, {
            emergencyRequestCharge: action.payload,
            emergencyRequestCharge_loading: false,
            emergencyRequestCharge_error: null,
          });
        case miscellaneousActionType.GET_EMERGENCY_REQUEST_CHARGE_ERR:
          return Object.assign({}, state, {
            emergencyRequestCharge: [],
            emergencyRequestCharge_loading: false,
            emergencyRequestCharge_error: action.payload,
          });
         
         
              // UPDATE emergencyRequestCharge DETAILS
        case miscellaneousActionType.PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_emergencyRequestCharge_details: action.result,
          });
        case miscellaneousActionType.PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

       //----------------------------------------------//


        //codHardCopyReports reducers type
        
    
        //create new codHardCopyReports
        case miscellaneousActionType.CREATE_COD_HARD_COPY_REPORTS_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.CREATE_COD_HARD_COPY_REPORTS_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_codHardCopyReports: action.result,
          });
        case miscellaneousActionType.CREATE_COD_HARD_COPY_REPORTS_ERROR:
          return Object.assign({}, state, {
            getallcodHardCopyReports_status: action.status,
            getallcodHardCopyReports_error: action.error,
          });
         
          
          // get codHardCopyReports
          case miscellaneousActionType.GET_COD_HARD_COPY_REPORTS_REQ:
          return Object.assign({}, state, {
            codHardCopyReports: [],
            codHardCopyReports_loading: true,
            codHardCopyReports_error: null,
          });
        case miscellaneousActionType.GET_COD_HARD_COPY_REPORTS_SUCC:
          return Object.assign({}, state, {
            codHardCopyReports: action.payload,
            codHardCopyReports_loading: false,
            codHardCopyReports_error: null,
          });
        case miscellaneousActionType.GET_COD_HARD_COPY_REPORTS_ERR:
          return Object.assign({}, state, {
            codHardCopyReports: [],
            codHardCopyReports_loading: false,
            codHardCopyReports_error: action.payload,
          });
         
         
              // UPDATE codHardCopyReports DETAILS
        case miscellaneousActionType.PUT_COD_HARD_COPY_REPORTS_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.PUT_COD_HARD_COPY_REPORTS_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_codHardCopyReports_details: action.result,
          });
        case miscellaneousActionType.PUT_COD_HARD_COPY_REPORTS_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

       //----------------------------------------------//


       //----------------------------------------------//


        //customerDeliveryCharges reducers type
        
    
        //create new customerDeliveryCharges
        case miscellaneousActionType.CREATE_CUSTOMER_DELIVERY_CHARGES_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.CREATE_CUSTOMER_DELIVERY_CHARGES_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_customerDeliveryCharges: action.result,
          });
        case miscellaneousActionType.CREATE_CUSTOMER_DELIVERY_CHARGES_ERROR:
          return Object.assign({}, state, {
            getallcustomerDeliveryCharges_status: action.status,
            getallcustomerDeliveryCharges_error: action.error,
          });
         
          
          // get customerDeliveryCharges
          case miscellaneousActionType.GET_CUSTOMER_DELIVERY_CHARGES_REQ:
          return Object.assign({}, state, {
            customerDeliveryCharges: [],
            customerDeliveryCharges_loading: true,
            customerDeliveryCharges_error: null,
          });
        case miscellaneousActionType.GET_CUSTOMER_DELIVERY_CHARGES_SUCC:
          return Object.assign({}, state, {
            customerDeliveryCharges: action.payload,
            customerDeliveryCharges_loading: false,
            customerDeliveryCharges_error: null,
          });
        case miscellaneousActionType.GET_CUSTOMER_DELIVERY_CHARGES_ERR:
          return Object.assign({}, state, {
            customerDeliveryCharges: [],
            customerDeliveryCharges_loading: false,
            customerDeliveryCharges_error: action.payload,
          });
         
         
              // UPDATE customerDeliveryCharges DETAILS
        case miscellaneousActionType.PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case miscellaneousActionType.PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_customerDeliveryCharges_details: action.result,
          });
        case miscellaneousActionType.PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

       //----------------------------------------------//





    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default miscellaneous;
    