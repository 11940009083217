import { getAction } from "connected-react-router";
import * as discountcouponActionType from "../../actions/action.discountcoupons/action.type";


const discountcoupon = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getalldiscountcoupon_error: null,
    getalldiscountcoupon_status: null,

    // discountcoupon

    create_discountcoupon: null,
    get_all_discountcoupon: [],
     all_discountcoupon: null,

     discountcoupon_by_id: [],
     discountcoupon_by_id_loading: false,
     discountcoupon_by_id_error: null,
     discountcoupon_enable_disable_status: null,
     update_discountcoupon_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //discountcoupon reducers type
        
    
        //create new discountcoupon
        case discountcouponActionType.CREATE_DISCOUNTCOUPON_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case discountcouponActionType.CREATE_DISCOUNTCOUPON_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_discountcoupon: action.result,
          });
        case discountcouponActionType.CREATE_DISCOUNTCOUPON_ERROR:
          return Object.assign({}, state, {
            getalldiscountcoupon_status: action.status,
            getalldiscountcoupon_error: action.error,
          });
         
          // all discountcoupons get
          case discountcouponActionType.GET_ALL_DISCOUNTCOUPON_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case discountcouponActionType.GET_ALL_DISCOUNTCOUPON_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_discountcoupon: action.result,
            });
          case discountcouponActionType.GET_ALL_DISCOUNTCOUPON_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get discountcoupons by id 
          case discountcouponActionType.GET_DISCOUNTCOUPON_BY_ID_REQ:
          return Object.assign({}, state, {
            discountcoupon_by_id: [],
            discountcoupon_by_id_loading: true,
            discountcoupon_by_id_error: null,
          });
        case discountcouponActionType.GET_DISCOUNTCOUPON_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            discountcoupon_by_id: action.payload,
            discountcoupon_by_id_loading: false,
            discountcoupon_by_id_error: null,
          });
        case discountcouponActionType.GET_DISCOUNTCOUPON_BY_ID_ERR:
          return Object.assign({}, state, {
            discountcoupon_by_id: [],
            discountcoupon_by_id_loading: false,
            discountcoupon_by_id_error: action.payload,
          });
          // update enable disable discountcoupon status 
          case discountcouponActionType.PUT_DISCOUNTCOUPON_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case discountcouponActionType.PUT_DISCOUNTCOUPON_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              discountcoupon_enable_disable_status:action.payload,
            });
          case discountcouponActionType.PUT_DISCOUNTCOUPON_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE discountcoupon DETAILS
        case discountcouponActionType.PUT_DISCOUNTCOUPON_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case discountcouponActionType.PUT_DISCOUNTCOUPON_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_discountcoupon_details: action.result,
          });
        case discountcouponActionType.PUT_DISCOUNTCOUPON_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default discountcoupon;
    
    
    