import { getAction } from "connected-react-router";
import * as serviceActionType from "../../actions/action.service/action.type";


const service = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallservice_error: null,
    getallservice_status: null,

    // service

    create_service: null,
    get_all_service: [],
     all_service: null,

     service_by_id: [],
     service_by_id_loading: false,
     service_by_id_error: null,
     service_enable_disable_status: null,
     update_service_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //service reducers type
        
    
        //create new service
        case serviceActionType.CREATE_SERVICE_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case serviceActionType.CREATE_SERVICE_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_service: action.result,
          });
        case serviceActionType.CREATE_SERVICE_ERROR:
          return Object.assign({}, state, {
            getallservice_status: action.status,
            getallservice_error: action.error,
          });
         
          // all services get
          case serviceActionType.GET_ALL_SERVICE_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case serviceActionType.GET_ALL_SERVICE_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_service: action.result,
            });
          case serviceActionType.GET_ALL_SERVICE_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get services by id 
          case serviceActionType.GET_SERVICE_BY_ID_REQ:
          return Object.assign({}, state, {
            service_by_id: [],
            service_by_id_loading: true,
            service_by_id_error: null,
          });
        case serviceActionType.GET_SERVICE_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            service_by_id: action.payload,
            service_by_id_loading: false,
            service_by_id_error: null,
          });
        case serviceActionType.GET_SERVICE_BY_ID_ERR:
          return Object.assign({}, state, {
            service_by_id: [],
            service_by_id_loading: false,
            service_by_id_error: action.payload,
          });
          // update enable disable service status 
          case serviceActionType.PUT_SERVICE_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case serviceActionType.PUT_SERVICE_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              service_enable_disable_status:action.payload,
            });
          case serviceActionType.PUT_SERVICE_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE service DETAILS
        case serviceActionType.PUT_SERVICE_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case serviceActionType.PUT_SERVICE_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_service_details: action.result,
          });
        case serviceActionType.PUT_SERVICE_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default service;
    
    
    