

//GET  ORDER
export const GET_ORDER_DATE_REQ  =  "GET_ORDER_DATE_REQ";
export const GET_ORDER_DATE_ERR  =  "GET_ORDER_DATE_ERR";
export const GET_ORDER_DATE_SUCC =  "GET_ORDER_DATE_SUCC";

//GET  ORDER
export const GET_ORDER_REQ  =  "GET_ORDER_REQ";
export const GET_ORDER_ERR  =  "GET_ORDER_ERR";
export const GET_ORDER_SUCC =  "GET_ORDER_SUCC";

////GET  ORDER_PROCESSED

export const GET_ORDER_PROCESSED_REQ  =  "GET_ORDER_PROCESSED_REQ";
export const GET_ORDER_PROCESSED_ERR  =  "GET_ORDER_PROCESSED_ERR";
export const GET_ORDER_PROCESSED_SUCC = "GET_ORDER_PROCESSED_SUCC";

//GET  CANCEL_ORDER
export const GET_CANCEL_ORDER_REQ  =  "GET_CANCEL_ORDER_REQ";
export const GET_CANCEL_ORDER_ERR  =  "GET_CANCEL_ORDER_ERR";
export const GET_CANCEL_ORDER_SUCC =  "GET_CANCEL_ORDER_SUCC";

//GET  CLOSED_ORDER
export const GET_CLOSED_ORDER_REQ  =  "GET_CLOSED_ORDER_REQ";
export const GET_CLOSED_ORDER_ERR  =  "GET_CLOSED_ORDER_ERR";
export const GET_CLOSED_ORDER_SUCC = "GET_CLOSED_ORDER_SUCC";