import { getAction } from "connected-react-router";
import * as technicianActionType from "../../actions/action.technician/action.type";


const technician = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getalltechnician_error: null,
    getalltechnician_status: null,

    // technician

    create_technician: null,
    get_all_technician: [],
     all_technician: null,

     technician_by_id: [],
     technician_by_id_loading: false,
     technician_by_id_error: null,
     technician_enable_disable_status: null,
     update_technician_details: null,
     technician_reports_by_technician_id:[],
     technician_reports_by_order_id:null,
    },
    action
) =>{

    switch (action.type) {
    
        //technician reducers type
        
    
        //create new technician
        case technicianActionType.CREATE_TECHNICIAN_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case technicianActionType.CREATE_TECHNICIAN_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_technician: action.result,
          });
        case technicianActionType.CREATE_TECHNICIAN_ERROR:
          return Object.assign({}, state, {
            getalltechnician_status: action.status,
            getalltechnician_error: action.error,
          });
         
          // all technicians get
          case technicianActionType.GET_ALL_TECHNICIAN_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case technicianActionType.GET_ALL_TECHNICIAN_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_technician: action.result,
            });
          case technicianActionType.GET_ALL_TECHNICIAN_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get technicians by id 
          case technicianActionType.GET_TECHNICIAN_BY_ID_REQ:
          return Object.assign({}, state, {
            technician_by_id: [],
            technician_by_id_loading: true,
            technician_by_id_error: null,
          });
        case technicianActionType.GET_TECHNICIAN_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            technician_by_id: action.payload,
            technician_by_id_loading: false,
            technician_by_id_error: null,
          });
        case technicianActionType.GET_TECHNICIAN_BY_ID_ERR:
          return Object.assign({}, state, {
            technician_by_id: [],
            technician_by_id_loading: false,
            technician_by_id_error: action.payload,
          });
          // update enable disable technician status 
          case technicianActionType.PUT_TECHNICIAN_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case technicianActionType.PUT_TECHNICIAN_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              technician_enable_disable_status:action.payload,
            });
          case technicianActionType.PUT_TECHNICIAN_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE technician DETAILS
        case technicianActionType.PUT_TECHNICIAN_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case technicianActionType.PUT_TECHNICIAN_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_technician_details: action.result,
          });
        case technicianActionType.PUT_TECHNICIAN_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    

          // get technician_reports_by_technician_id
          case technicianActionType.GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_REQ:
          return Object.assign({}, state, {
            technician_reports_by_technician_id: [],
            status: action.status,
            
          });
        case technicianActionType.GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_SUCC:
          return Object.assign({}, state, {
            technician_reports_by_technician_id: action.payload,
            status: action.status,
          });
        case technicianActionType.GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID_ERR:
          return Object.assign({}, state, {
            technician_reports_by_technician_id: [],
            status: action.status,
            error: action.payload,
          });
    
           // get technician_reports_by_order_id
           case technicianActionType.GET_TECHNICIAN_REPORTS_BY_ORDER_ID_REQ:
            return Object.assign({}, state, {
              technician_reports_by_order_id: null,
              status: action.status,
              
            });
          case technicianActionType.GET_TECHNICIAN_REPORTS_BY_ORDER_ID_SUCC:
            return Object.assign({}, state, {
              technician_reports_by_order_id: action.payload,
              status: action.status,
            });
          case technicianActionType.GET_TECHNICIAN_REPORTS_BY_ORDER_ID_ERR:
            return Object.assign({}, state, {
              technician_reports_by_order_id: null,
              status: action.status,
              error: action.payload,
            });
      
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default technician;
    
    
    