// import logo from './logo.svg';
// import './App.css';
import React, { Component } from "react";
import { HashRouter,BrowserRouter, Route, Switch } from "react-router-dom";

import {Row, Col,  Card,
  CardBody,
  CardGroup,
  Container, Modal, ModalHeader, ModalBody} from "reactstrap";
import { CSpinner} from '@coreui/react'
 import "./App.scss";
 import CustomRoute from "./utils/CustomRoute";
 
// Pages
const Login = React.lazy(() => import("./views/Users/Login"));
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout.js"));
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const ChangePassword = React.lazy(() => import("./views/ChangePassword/ChangePassword"));
// Admin
const AdminList = React.lazy(() => import("./views/Admin/AdminList"));
const RegisterAdmin = React.lazy(() => import("./views/Admin/RegisterAdmin"));

// Area 

const CityList = React.lazy(() => import("./views/Area/City/CityList"));
const AreaList = React.lazy(() => import("./views/Area/AreaList"));
// Technician
const TechnicianList = React.lazy(() => import("./views/Technician/TechnicianList"));
const RegisterTechnician = React.lazy(() => import("./views/Technician/RegisterTechnician"));
const TechnicianReportList = React.lazy(() => import("./views/Technician/TechnicianReportList"));
const TechnicianReportView = React.lazy(() => import("./views/Technician/TechnicianReportView"));

// Services
const CategoryList = React.lazy(() => import("./views/Services/Category/CategoryList.js"));
const PackageList = React.lazy(() => import("./views/Services/Package/PackageList.js"));
const ServicesList = React.lazy(() => import("./views/Services/ServicesList"));
const AddServices = React.lazy(() => import("./views/Services/AddServices"));

// Discount coupons 
const DiscountCouponsList = React.lazy(() => import("./views/DiscountCoupons/DiscountCouponsList"));

// FAQs
const FAQList = React.lazy(() => import("./views/FAQs/FAQList"));
const AddFAQs = React.lazy(() => import("./views/FAQs/AddFAQs"));

// Feedback 
const FeedbackList = React.lazy(() => import("./views/Feedback/FeedbackList"));
const FeedbackUserRating = React.lazy(() => import("./views/Feedback/FeedbackUserRating"));
const FeedbackUserRatingByOrderIdView = React.lazy(() => import("./views/Feedback/FeedbackUserRatingByOrderIdView"));
const FeedbackQuestionDetailedRatingView = React.lazy(() => import("./views/Feedback/FeedbackQuestionDetailedRatingView"));

// Article 
const ArticleList = React.lazy(() => import("./views/Articles/ArticleList"));
const AddArticle = React.lazy(() => import("./views/Articles/AddArticle"));
const EditArticle = React.lazy(() => import("./views/Articles/EditArticle"));

// Time Slots

const TimeSlotsList = React.lazy(() => import("./views/TimeSlots/TimeSlotsList"));

// Miscellaneous 
const Miscellaneous = React.lazy(() => import("./views/Miscellaneous/Miscellaneous"));

// Users View
const UserList = React.lazy(() => import("./views/UsersView/UserList"));
const UserViewById = React.lazy(() => import("./views/UsersView/UserViewById"));
const RegisterUser = React.lazy(() => import("./views/UsersView/RegisterUser"));
const UserEdit = React.lazy(() => import("./views/UsersView/UserEdit"));

// Orders
const OrdersList = React.lazy(() => import("./views/Orders/OrdersList"));
const ClosedOrderList = React.lazy(() => import("./views/Orders/ClosedOrderList"));
const OpenOrderList = React.lazy(() => import("./views/Orders/OpenOrderList"));
const OrderView = React.lazy(() => import("./views/Orders/OrderView"));

// family members
const FamilyMembersList = React.lazy(() => import("./views/FamilyMembers/FamilyMembersList"));
const FamilyMemberEdit = React.lazy(() => import("./views/FamilyMembers/FamilyMemberEdit"));
const FamilyMemberView = React.lazy(() => import("./views/FamilyMembers/FamilyMemberView"));

// Call back request
const CallBackRequestList = React.lazy(() => import("./views/RequestCallBack/CallBackRequestList"));
const CancelledCallBackRequestList = React.lazy(() => import("./views/RequestCallBack/CancelledCallBackRequestList"));
const CallBackRequestView = React.lazy(() => import("./views/RequestCallBack/CallBackRequestView"));
const CallBackRequestViewOrder = React.lazy(() => import("./views/RequestCallBack/CallBackRequestViewOrder"));

// Support 
const OpenTicket = React.lazy(() => import("./views/Support/OpenTicket"));
const SupportHistory = React.lazy(() => import("./views/Support/SupportHistory"));
const SupportView = React.lazy(() => import("./views/Support/SupportView"));

// Notifications
// Push Notification
const PushNotificationList = React.lazy(() => import("./views/Notifications/PushNotificationList"));
const CreatePushNotification = React.lazy(() => import("./views/Notifications/CreatePushNotification"));

// Custom Notification
// custom Notification Individual view 
const CustomNotificationView = React.lazy(() => import("./utils/CustomNotificationView/CustomNotificationView"));
const CustomNotificationViewAll = React.lazy(() => import("./utils/CustomNotificationView/CustomNotificationViewAll"));

const Page400 = React.lazy(() => import("./views/Pages/Page400"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

// const loading = () => (
//   <div className="animated fadeIn pt-3 text-center">Loading...</div>
// );
const loading = () => (
  <div className="animated fadeIn app flex-row align-items-center">
  {/* Loading... */}
  <Container>
  <Row className="justify-content-center">
  <Col >
            <CardGroup>
              <Card className="p-1">
                <CardBody className="align-self-center">
                <CSpinner color="primary" />
                </CardBody>
                </Card>
                </CardGroup>

                


                </Col>
  </Row>
  
  </Container>
  </div>
);
function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
      <Switch>
      <Route exact path="/login" name="Login Page" component={Login} />
      <Route
              exact path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact path="/400"
              name="Page 400"
              render={(props) => <Page400 {...props} />}
            />
            {/* <Route
              exact path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            /> */}

      <CustomRoute
              protectedPath
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
            {/* dashboard */}
            <CustomRoute
              protectedPath
              name="Dashboard"
              path="/dashboard"
              render={(props) => <Dashboard {...props} />}
            />

            {/* 500 error page */}
            <CustomRoute
              // protectedPath
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />


            {/* ChangePassword */}

           <CustomRoute
              protectedPath
              name="ChangePassword"
              path="/ChangePassword"
              render={(props) => <ChangePassword {...props} />}
            />
            {/* Admin */}

            <CustomRoute
              protectedPath
              name="AdminList"
              path="/Admin/AdminList"
              render={(props) => <AdminList {...props} />}
            />

<CustomRoute
              protectedPath
              name="RegisterAdmin"
              path="/Admin/RegisterAdmin"
              render={(props) => <RegisterAdmin {...props} />}
            />

             {/* Technician */}

             <CustomRoute
              protectedPath
              name="TechnicianList"
              path="/Technician/TechnicianList"
              render={(props) => <TechnicianList {...props} />}
            />

<CustomRoute
              protectedPath
              name="RegisterTechnician"
              path="/Technician/RegisterTechnician"
              render={(props) => <RegisterTechnician {...props} />}
            />

<CustomRoute
              protectedPath
              name="TechnicianReportList"
              path="/Technician/TechnicianReportList"
              render={(props) => <TechnicianReportList {...props} />}
            />

<CustomRoute
              protectedPath
              name="TechnicianReportList"
              path="/Technician/TechnicianReportView"
              render={(props) => <TechnicianReportView {...props} />}
            />

            {/* Area */}
            <CustomRoute
              protectedPath
              name="CityList"
              path="/Area/City/CityList"
              render={(props) => <CityList {...props} />}
            />

            <CustomRoute
              protectedPath
              name="AreaList"
              path="/Area/AreaList"
              render={(props) => <AreaList {...props} />}
            />

               {/* Services */}

               <CustomRoute
              protectedPath
              name="ServicesList"
              path="/Services/ServicesList"
              render={(props) => <ServicesList {...props} />}
            />

<CustomRoute
              protectedPath
              name="AddServices"
              path="/Services/AddServices"
              render={(props) => <AddServices {...props} />}
            />

<CustomRoute
              protectedPath
              name="CategoryList"
              path="/Services/Category/CategoryList"
              render={(props) => <CategoryList {...props} />}
            />

<CustomRoute
              protectedPath
              name="PackageList"
              path="/Services/Package/PackageList"
              render={(props) => <PackageList {...props} />}
            />

            {/* Discount coupons */}

            <CustomRoute
              protectedPath
              name="DiscountCouponsList"
              path="/DiscountCoupons/DiscountCouponsList"
              render={(props) => <DiscountCouponsList {...props} />}
            />

            {/* Time Slots  */}

            <CustomRoute
              protectedPath
              name="TimeSlotsList"
              path="/TimeSlots/TimeSlotsList"
              render={(props) => <TimeSlotsList {...props} />}
            />

            {/* FAQs */}
            <CustomRoute
              protectedPath
              name="FAQList"
              path="/FAQs/FAQList"
              render={(props) => <FAQList {...props} />}
            />

<CustomRoute
              protectedPath
              name="AddFAQs"
              path="/FAQs/AddFAQs"
              render={(props) => <AddFAQs {...props} />}
            />

            {/* Feedback  */}

            <CustomRoute
              protectedPath
              name="FeedbackList"
              path="/Feedback/FeedbackList"
              render={(props) => <FeedbackList {...props} />}
            />
            <CustomRoute
              protectedPath
              name="FeedbackUserRating"
              path="/Feedback/FeedbackUserRating"
              render={(props) => <FeedbackUserRating {...props} />}
            />

<CustomRoute
              protectedPath
              name="FeedbackUserRatingByOrderIdView"
              path="/Feedback/FeedbackUserRatingByOrderIdView"
              render={(props) => <FeedbackUserRatingByOrderIdView {...props} />}
            />


<CustomRoute
              protectedPath
              name="FeedbackQuestionDetailedRatingView"
              path="/Feedback/FeedbackQuestionDetailedRatingView"
              render={(props) => <FeedbackQuestionDetailedRatingView {...props} />}
            />
           
           {/* Article  */}

           <CustomRoute
              protectedPath
              name="ArticleList"
              path="/Articles/ArticleList"
              render={(props) => <ArticleList {...props} />}
            />
               <CustomRoute
              protectedPath
              name="AddArticle"
              path="/Articles/AddArticle"
              render={(props) => <AddArticle {...props} />}
            />

<CustomRoute
              protectedPath
              path="/Articles/EditArticle"
              name="EditArticle"
              
              render={(props) => <EditArticle {...props} />}
            />
           {/* Miscellaneous  */}

           <CustomRoute
              protectedPath
              name="Miscellaneous"
              path="/Miscellaneous/Miscellaneous"
              render={(props) => <Miscellaneous {...props} />}
            />
            
             {/* User View */}

             <CustomRoute
              protectedPath
              name="UserList"
              path="/UsersView/UserList"
              render={(props) => <UserList {...props} />}
            />

            <CustomRoute
              protectedPath
              name="UserViewById"
              path="/UsersView/UserViewById"
              render={(props) => <UserViewById {...props} />}
            />
            
            <CustomRoute
              protectedPath
              name="RegisterUser"
              path="/UsersView/RegisterUser"
              render={(props) => <RegisterUser {...props} />}
            />

<CustomRoute
              protectedPath
              name="UserEdit"
              path="/UsersView/UserEdit"
              render={(props) => <UserEdit {...props} />}
            />
               {/* Orders */}

               <CustomRoute
              protectedPath
              name="OrdersList"
              path="/Orders/OrdersList"
              render={(props) => <OrdersList {...props} />}
            />
            <CustomRoute
              protectedPath
              name="ClosedOrderList"
              path="/Orders/ClosedOrderList"
              render={(props) => <ClosedOrderList {...props} />}
            />

             <CustomRoute
              protectedPath
              name="OpenOrderList"
              path="/Orders/OpenOrderList"
              render={(props) => <OpenOrderList {...props} />}
            />

              <CustomRoute
              protectedPath
              name="OrderView"
              path="/Orders/OrderView"
              render={(props) => <OrderView {...props} />}
            />
             {/* FamilyMembers */}

             <CustomRoute
              protectedPath
              name="FamilyMembersList"
              path="/FamilyMembers/FamilyMembersList"
              render={(props) => <FamilyMembersList {...props} />}
            />

            <CustomRoute
              protectedPath
              name="FamilyMemberEdit"
              path="/FamilyMembers/FamilyMemberEdit"
              render={(props) => <FamilyMemberEdit {...props} />}
            />

            <CustomRoute
              protectedPath
              name="FamilyMemberView"
              path="/FamilyMembers/FamilyMemberView"
              render={(props) => <FamilyMemberView {...props} />}
            />        

            {/* Request CallBack */}

            <CustomRoute
              protectedPath
              name="CallBackRequestList"
              path="/RequestCallBack/CallBackRequestList"
              render={(props) => <CallBackRequestList {...props} />}
            />

          <CustomRoute
              protectedPath
              name="CancelledCallBackRequestList"
              path="/RequestCallBack/CancelledCallBackRequestList"
              render={(props) => <CancelledCallBackRequestList {...props} />}
            />

            <CustomRoute
              protectedPath
              name="CallBackRequestView"
              path="/RequestCallBack/CallBackRequestView"
              render={(props) => <CallBackRequestView {...props} />}
            />

            <CustomRoute
              protectedPath
              name="CallBackRequestViewOrder"
              path="/RequestCallBack/CallBackRequestViewOrder"
              render={(props) => <CallBackRequestViewOrder {...props} />}
            />

            {/* Support  */}
            <CustomRoute
              protectedPath
              name="OpenTicket"
              path="/Support/OpenTicket"
              render={(props) => <OpenTicket {...props} />}
            />

            <CustomRoute
              protectedPath
              name="SupportHistory"
              path="/Support/SupportHistory"
              render={(props) => <SupportHistory {...props} />}
            />

            <CustomRoute
              protectedPath
              name="SupportView"
              path="/Support/SupportView"
              render={(props) => <SupportView {...props} />}
            />

            {/* Notifications */}

            <CustomRoute
              protectedPath
              name="PushNotificationList"
              path="/Notifications/PushNotificationList"
              render={(props) => <PushNotificationList {...props} />}
            />

            <CustomRoute
              protectedPath
              name="CreatePushNotification"
              path="/Notifications/CreatePushNotification"
              render={(props) => <CreatePushNotification {...props} />}
            />

            {/* custom Notification Utils*/}
            <CustomRoute
              protectedPath
              name="CustomNotificationView"
              path="/CustomNotificationView/CustomNotificationView"
              render={(props) => <CustomNotificationView {...props} />}
            />
              <CustomRoute
              protectedPath
              name="CustomNotificationViewAll"
              path="/CustomNotificationView/CustomNotificationViewAll"
              render={(props) => <CustomNotificationViewAll {...props} />}
            />

      </Switch>
      </React.Suspense>
    </BrowserRouter>
    
  );
}

export default App;
