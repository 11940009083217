// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_DISCOUNTCOUPON_REQUEST = "CREATE_DISCOUNTCOUPON_REQUEST";
export const CREATE_DISCOUNTCOUPON_SUCCESS = "CREATE_DISCOUNTCOUPON_SUCCESS";
export const CREATE_DISCOUNTCOUPON_ERROR = "CREATE_DISCOUNTCOUPON_ERROR";

//GET ALL  DISCOUNTCOUPON
export const GET_ALL_DISCOUNTCOUPON_REQ = "GET_ALL_DISCOUNTCOUPON_REQ";
export const GET_ALL_DISCOUNTCOUPON_ERR = "GET_ALL_DISCOUNTCOUPON_ERR";
export const GET_ALL_DISCOUNTCOUPON_SUCC = "GET_ALL_DISCOUNTCOUPON_SUCC";

//get DISCOUNTCOUPON by id
export const GET_DISCOUNTCOUPON_BY_ID_REQ = "GET_DISCOUNTCOUPON_BY_ID_REQ";
export const GET_DISCOUNTCOUPON_BY_ID_ERR = "GET_DISCOUNTCOUPON_BY_ID_ERR";
export const GET_DISCOUNTCOUPON_BY_ID_SUCCESS = "GET_DISCOUNTCOUPON_BY_ID_SUCCESS";

// ENABLE_DISABLE DISCOUNTCOUPON
export const PUT_DISCOUNTCOUPON_STATUS_BY_ID_REQ = "PUT_DISCOUNTCOUPON_STATUS_BY_ID_REQ";
export const PUT_DISCOUNTCOUPON_STATUS_BY_ID_ERR = "PUT_DISCOUNTCOUPON_STATUS_BY_ID_ERR";
export const PUT_DISCOUNTCOUPON_STATUS_BY_ID_SUCC = "PUT_DISCOUNTCOUPON_STATUS_BY_ID_SUCC";


//UPDATE DISCOUNTCOUPON DETAILS
export const PUT_DISCOUNTCOUPON_DETAILS_REQ = "PUT_DISCOUNTCOUPON_DETAILS_REQ";
export const PUT_DISCOUNTCOUPON_DETAILS_SUCC = "PUT_DISCOUNTCOUPON_DETAILS_SUCC";
export const PUT_DISCOUNTCOUPON_DETAILS_ERR = "PUT_DISCOUNTCOUPON_DETAILS_ERR";


// //UPDATE DISCOUNTCOUPON DETAILS
// export const PUT_CAMP_DISCOUNTCOUPON_REQ = "PUT_CAMP_DISCOUNTCOUPON_REQ";
// export const PUT_CAMP_DISCOUNTCOUPON_SUCC = "PUT_CAMP_DISCOUNTCOUPON_SUCC";
// export const PUT_CAMP_DISCOUNTCOUPON_ERR = "PUT_CAMP_DISCOUNTCOUPON_ERR";








