// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";

//GET ALL  ORDER
export const GET_ALL_ORDER_REQ = "GET_ALL_ORDER_REQ";
export const GET_ALL_ORDER_ERR = "GET_ALL_ORDER_ERR";
export const GET_ALL_ORDER_SUCC = "GET_ALL_ORDER_SUCC";

//get ORDER by id
export const GET_ORDER_BY_ID_REQ = "GET_ORDER_BY_ID_REQ";
export const GET_ORDER_BY_ID_ERR = "GET_ORDER_BY_ID_ERR";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";

// ORDER_STATUS_UPDATE
export const PUT_ORDER_STATUS_BY_ID_REQ = "PUT_ORDER_STATUS_BY_ID_REQ";
export const PUT_ORDER_STATUS_BY_ID_ERR = "PUT_ORDER_STATUS_BY_ID_ERR";
export const PUT_ORDER_STATUS_BY_ID_SUCC = "PUT_ORDER_STATUS_BY_ID_SUCC";


//UPDATE ORDER DETAILS
export const PUT_ORDER_DETAILS_REQ = "PUT_ORDER_DETAILS_REQ";
export const PUT_ORDER_DETAILS_SUCC = "PUT_ORDER_DETAILS_SUCC";
export const PUT_ORDER_DETAILS_ERR = "PUT_ORDER_DETAILS_ERR";

//Post Assign Technician by ORDER id
export const POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ = "POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ";
export const POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR = "POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR";
export const POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS = "POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS";


//GET Assign Technician by ORDER id
export const GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ = "GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ";
export const GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR = "GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR";
export const GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS = "GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS";

//Update Assign Technician by ORDER id
export const UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ = "UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ";
export const UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR = "UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR";
export const UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS = "UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS";

// CANCEL_ORDER_ID
export const CANCEL_ORDER_BY_ORDER_ID_REQ = "CANCEL_ORDER_BY_ORDER_ID_REQ";
export const CANCEL_ORDER_BY_ORDER_ID_ERR = "CANCEL_ORDER_BY_ORDER_ID_ERR";
export const CANCEL_ORDER_BY_ORDER_ID_SUCC = "CANCEL_ORDER_BY_ORDER_ID_SUCC";

//GET ALL REFUNDABLE ORDER
export const GET_ALL_REFUNDABLE_ORDER_REQ = "GET_ALL_REFUNDABLE_ORDER_REQ";
export const GET_ALL_REFUNDABLE_ORDER_ERR = "GET_ALL_REFUNDABLE_ORDER_ERR";
export const GET_ALL_REFUNDABLE_ORDER_SUCC = "GET_ALL_REFUNDABLE_ORDER_SUCC";

// POST_REFUNDABLE_ORDER_BY_ORDER_ID
export const POST_REFUNDABLE_ORDER_BY_ORDER_ID_REQ = "POST_REFUNDABLE_ORDER_BY_ORDER_ID_REQ";
export const POST_REFUNDABLE_ORDER_BY_ORDER_ID_ERR = "POST_REFUNDABLE_ORDER_BY_ORDER_ID_ERR";
export const POST_REFUNDABLE_ORDER_BY_ORDER_ID_SUCC = "POST_REFUNDABLE_ORDER_BY_ORDER_ID_SUCC";




