import { getAction } from "connected-react-router";
import * as categoryActionType from "../../actions/action.category/action.type";


const category = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallcategory_error: null,
    getallcategory_status: null,

    // category

    create_category: null,
    get_all_category: [],
     all_category: null,

     category_by_id: [],
     category_by_id_loading: false,
     category_by_id_error: null,
     category_enable_disable_status: null,
     update_category_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //category reducers type
        
    
        //create new category
        case categoryActionType.CREATE_CATEGORY_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case categoryActionType.CREATE_CATEGORY_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_category: action.result,
          });
        case categoryActionType.CREATE_CATEGORY_ERROR:
          return Object.assign({}, state, {
            getallcategory_status: action.status,
            getallcategory_error: action.error,
          });
         
          // all categorys get
          case categoryActionType.GET_ALL_CATEGORY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case categoryActionType.GET_ALL_CATEGORY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_category: action.result,
            });
          case categoryActionType.GET_ALL_CATEGORY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get categorys by id 
          case categoryActionType.GET_CATEGORY_BY_ID_REQ:
          return Object.assign({}, state, {
            category_by_id: [],
            category_by_id_loading: true,
            category_by_id_error: null,
          });
        case categoryActionType.GET_CATEGORY_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            category_by_id: action.payload,
            category_by_id_loading: false,
            category_by_id_error: null,
          });
        case categoryActionType.GET_CATEGORY_BY_ID_ERR:
          return Object.assign({}, state, {
            category_by_id: [],
            category_by_id_loading: false,
            category_by_id_error: action.payload,
          });
          // update enable disable category status 
          case categoryActionType.PUT_CATEGORY_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case categoryActionType.PUT_CATEGORY_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              category_enable_disable_status:action.payload,
            });
          case categoryActionType.PUT_CATEGORY_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE category DETAILS
        case categoryActionType.PUT_CATEGORY_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case categoryActionType.PUT_CATEGORY_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_category_details: action.result,
          });
        case categoryActionType.PUT_CATEGORY_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default category;
    
    
    