// Create  push notifications

export const CREATE_PUSH_NOTIFICATIONS_REQUEST = "CREATE_PUSH_NOTIFICATIONS_REQUEST";
export const CREATE_PUSH_NOTIFICATIONS_SUCCESS = "CREATE_PUSH_NOTIFICATIONS_SUCCESS";
export const CREATE_PUSH_NOTIFICATIONS_ERROR = "CREATE_PUSH_NOTIFICATIONS_ERROR";

// SEND push notifications

export const SEND_PUSH_NOTIFICATIONS_REQUEST = "SEND_PUSH_NOTIFICATIONS_REQUEST";
export const SEND_PUSH_NOTIFICATIONS_SUCCESS = "SEND_PUSH_NOTIFICATIONS_SUCCESS";
export const SEND_PUSH_NOTIFICATIONS_ERROR = "SEND_PUSH_NOTIFICATIONS_ERROR";


//GET ALL  PUSH_NOTIFICATIONS
export const GET_ALL_PUSH_NOTIFICATIONS_REQ = "GET_ALL_PUSH_NOTIFICATIONS_REQ";
export const GET_ALL_PUSH_NOTIFICATIONS_ERR = "GET_ALL_PUSH_NOTIFICATIONS_ERR";
export const GET_ALL_PUSH_NOTIFICATIONS_SUCC = "GET_ALL_PUSH_NOTIFICATIONS_SUCC";

// ENABLE_DISABLE PUSH_NOTIFICATIONS
export const PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_REQ = "PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_REQ";
export const PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_ERR = "PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_ERR";
export const PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_SUCC = "PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_SUCC";