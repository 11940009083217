import * as supportActionType from "../../actions/action.support/action.type";


const support = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallsupport_error: null,
    getallsupport_status: null,

    // support

    create_support: null,
    get_all_support: [],
     all_support: null,

     support_by_ticket_id: [],
     support_by_ticket_id_loading: false,
     support_by_ticket_id_error: null,
     support_status_by_ticket_id: null,
     update_support_details: null,
     assign_technician_by_support_id:null,
     get_assign_technician_by_support_id:null,


     create_support_conversation_by_ticket_id: null,
     get_support_conversation_by_ticket_id: null,
     support_conversation_by_ticket_id: [],
     support_conversation_by_ticket_id_loading: false,
     support_conversation_by_ticket_id_error: null,
    },
    action
) =>{

    switch (action.type) {
    
        //support reducers type
        
    
        //create new support
        case supportActionType.CREATE_SUPPORT_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case supportActionType.CREATE_SUPPORT_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_support: action.result,
          });
        case supportActionType.CREATE_SUPPORT_ERROR:
          return Object.assign({}, state, {
            getallsupport_status: action.status,
            getallsupport_error: action.error,
          });
         
          // all supports get
          case supportActionType.GET_ALL_SUPPORT_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case supportActionType.GET_ALL_SUPPORT_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_support: action.result,
            });
          case supportActionType.GET_ALL_SUPPORT_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get supports by id 
          case supportActionType.GET_SUPPORT_BY_TICKET_ID_REQ:
          return Object.assign({}, state, {
            support_by_ticket_id: [],
            support_by_ticket_id_loading: true,
            support_by_ticket_id_error: null,
          });
        case supportActionType.GET_SUPPORT_BY_TICKET_ID_SUCCESS:
          return Object.assign({}, state, {
            support_by_ticket_id: action.payload,
            support_by_ticket_id_loading: false,
            support_by_ticket_id_error: null,
          });
        case supportActionType.GET_SUPPORT_BY_TICKET_ID_ERR:
          return Object.assign({}, state, {
            support_by_ticket_id: [],
            support_by_ticket_id_loading: false,
            support_by_ticket_id_error: action.payload,
          });
          // update support status by ticket id
          case supportActionType.PUT_SUPPORT_STATUS_BY_TICKET_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case supportActionType.PUT_SUPPORT_STATUS_BY_TICKET_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              support_status_by_ticket_id:action.payload,
            });
          case supportActionType.PUT_SUPPORT_STATUS_BY_TICKET_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE support DETAILS
        case supportActionType.PUT_SUPPORT_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case supportActionType.PUT_SUPPORT_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_support_details: action.result,
          });
        case supportActionType.PUT_SUPPORT_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });


            //create new support
        case supportActionType.CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case supportActionType.CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_support_conversation_by_ticket_id: action.result,
          });
        case supportActionType.CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR:
          return Object.assign({}, state, {
            getallsupport_status: action.status,
            getallsupport_error: action.error,
          });

            //get support conversation
        case supportActionType.GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case supportActionType.GET_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            get_support_conversation_by_ticket_id: action.result,
          });
        case supportActionType.GET_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR:
          return Object.assign({}, state, {
            getallsupport_status: action.status,
            getallsupport_error: action.error,
          });

         
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default support;
    
    
    