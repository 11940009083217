// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_USER_VIEW_REQUEST = "CREATE_USER_VIEW_REQUEST";
export const CREATE_USER_VIEW_SUCCESS = "CREATE_USER_VIEW_SUCCESS";
export const CREATE_USER_VIEW_ERROR = "CREATE_USER_VIEW_ERROR";

//GET ALL  USER_VIEW
export const GET_ALL_USER_VIEW_REQ = "GET_ALL_USER_VIEW_REQ";
export const GET_ALL_USER_VIEW_ERR = "GET_ALL_USER_VIEW_ERR";
export const GET_ALL_USER_VIEW_SUCC = "GET_ALL_USER_VIEW_SUCC";

//get USER_VIEW by id
export const GET_USER_VIEW_BY_ID_REQ = "GET_USER_VIEW_BY_ID_REQ";
export const GET_USER_VIEW_BY_ID_ERR = "GET_USER_VIEW_BY_ID_ERR";
export const GET_USER_VIEW_BY_ID_SUCCESS = "GET_USER_VIEW_BY_ID_SUCCESS";

//get ALL ORDER DETAILS by USER id
export const GET_ALL_ORDERS_DETAILS_BY_USER_ID_REQ = "GET_ALL_ORDERS_DETAILS_BY_USER_ID_REQ";
export const GET_ALL_ORDERS_DETAILS_BY_USER_ID_ERR = "GET_ALL_ORDERS_DETAILS_BY_USER_ID_ERR";
export const GET_ALL_ORDERS_DETAILS_BY_USER_ID_SUCCESS = "GET_ALL_ORDERS_DETAILS_BY_USER_ID_SUCCESS";

// ENABLE_DISABLE USER_VIEW
export const PUT_USER_VIEW_STATUS_BY_ID_REQ = "PUT_USER_VIEW_STATUS_BY_ID_REQ";
export const PUT_USER_VIEW_STATUS_BY_ID_ERR = "PUT_USER_VIEW_STATUS_BY_ID_ERR";
export const PUT_USER_VIEW_STATUS_BY_ID_SUCC = "PUT_USER_VIEW_STATUS_BY_ID_SUCC";


//UPDATE USER_VIEW DETAILS
export const PUT_USER_VIEW_DETAILS_REQ = "PUT_USER_VIEW_DETAILS_REQ";
export const PUT_USER_VIEW_DETAILS_SUCC = "PUT_USER_VIEW_DETAILS_SUCC";
export const PUT_USER_VIEW_DETAILS_ERR = "PUT_USER_VIEW_DETAILS_ERR";


// get User view by user id 
export const GET_USER_VIEW_BY_USER_ID_REQ = "GET_USER_VIEW_BY_USER_ID_REQ";
export const GET_USER_VIEW_BY_USER_ID_ERR = "GET_USER_VIEW_BY_USER_ID_ERR";
export const GET_USER_VIEW_BY_USER_ID_SUCCESS = "GET_USER_VIEW_BY_USER_ID_SUCCESS";










