// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_FAQ_REQUEST = "CREATE_FAQ_REQUEST";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const CREATE_FAQ_ERROR = "CREATE_FAQ_ERROR";

//GET ALL  FAQ
export const GET_ALL_FAQ_REQ = "GET_ALL_FAQ_REQ";
export const GET_ALL_FAQ_ERR = "GET_ALL_FAQ_ERR";
export const GET_ALL_FAQ_SUCC = "GET_ALL_FAQ_SUCC";

//get FAQ by id
export const GET_FAQ_BY_ID_REQ = "GET_FAQ_BY_ID_REQ";
export const GET_FAQ_BY_ID_ERR = "GET_FAQ_BY_ID_ERR";
export const GET_FAQ_BY_ID_SUCCESS = "GET_FAQ_BY_ID_SUCCESS";

// ENABLE_DISABLE FAQ
export const PUT_FAQ_STATUS_BY_ID_REQ = "PUT_FAQ_STATUS_BY_ID_REQ";
export const PUT_FAQ_STATUS_BY_ID_ERR = "PUT_FAQ_STATUS_BY_ID_ERR";
export const PUT_FAQ_STATUS_BY_ID_SUCC = "PUT_FAQ_STATUS_BY_ID_SUCC";


//UPDATE FAQ DETAILS
export const PUT_FAQ_DETAILS_REQ = "PUT_FAQ_DETAILS_REQ";
export const PUT_FAQ_DETAILS_SUCC = "PUT_FAQ_DETAILS_SUCC";
export const PUT_FAQ_DETAILS_ERR = "PUT_FAQ_DETAILS_ERR";


// //UPDATE FAQ DETAILS
// export const PUT_CAMP_FAQ_REQ = "PUT_CAMP_FAQ_REQ";
// export const PUT_CAMP_FAQ_SUCC = "PUT_CAMP_FAQ_SUCC";
// export const PUT_CAMP_FAQ_ERR = "PUT_CAMP_FAQ_ERR";








