import * as user_viewActionType from "../../actions/action.userView/action.type";


const user_view = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getalluser_view_error: null,
    getalluser_view_status: null,

    // user_view

    create_user_view: null,
    get_all_user_view: [],
     all_user_view: null,

     user_view_by_id: [],
     user_view_by_id_loading: false,
     user_view_by_id_error: null,
     user_view_enable_disable_status: null,
     update_user_view_details: null,


     user_view_by_user_id: [],
     user_view_by_user_id_loading: false,
     user_view_by_user_id_error: null,
    },
    action
) =>{

    switch (action.type) {
    
        //user_view reducers type
        
    
        //create new user_view
        case user_viewActionType.CREATE_USER_VIEW_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case user_viewActionType.CREATE_USER_VIEW_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_user_view: action.result,
          });
        case user_viewActionType.CREATE_USER_VIEW_ERROR:
          return Object.assign({}, state, {
            getalluser_view_status: action.status,
            getalluser_view_error: action.error,
          });
         
          // all user_views get
          case user_viewActionType.GET_ALL_USER_VIEW_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case user_viewActionType.GET_ALL_USER_VIEW_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_user_view: action.result,
            });
          case user_viewActionType.GET_ALL_USER_VIEW_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get user_views by id 
          case user_viewActionType.GET_USER_VIEW_BY_ID_REQ:
          return Object.assign({}, state, {
            user_view_by_id: [],
            user_view_by_id_loading: true,
            user_view_by_id_error: null,
          });
        case user_viewActionType.GET_USER_VIEW_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            user_view_by_id: action.payload,
            user_view_by_id_loading: false,
            user_view_by_id_error: null,
          });
        case user_viewActionType.GET_USER_VIEW_BY_ID_ERR:
          return Object.assign({}, state, {
            user_view_by_id: [],
            user_view_by_id_loading: false,
            user_view_by_id_error: action.payload,
          });
          // update enable disable user_view status 
          case user_viewActionType.PUT_USER_VIEW_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case user_viewActionType.PUT_USER_VIEW_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              user_view_enable_disable_status:action.payload,
            });
          case user_viewActionType.PUT_USER_VIEW_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE user_view DETAILS
        case user_viewActionType.PUT_USER_VIEW_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case user_viewActionType.PUT_USER_VIEW_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_user_view_details: action.result,
          });
        case user_viewActionType.PUT_USER_VIEW_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });


          // get user_views by user id 
          case user_viewActionType.GET_USER_VIEW_BY_USER_ID_REQ:
          return Object.assign({}, state, {
            user_view_by_user_id: [],
            user_view_by_user_id_loading: true,
            user_view_by_user_id_error: null,
          });
        case user_viewActionType.GET_USER_VIEW_BY_USER_ID_SUCCESS:
          return Object.assign({}, state, {
            user_view_by_user_id: action.payload,
            user_view_by_user_id_loading: false,
            user_view_by_user_id_error: null,
          });
        case user_viewActionType.GET_USER_VIEW_BY_USER_ID_ERR:
          return Object.assign({}, state, {
            user_view_by_user_id: [],
            user_view_by_user_id_loading: false,
            user_view_by_user_id_error: action.payload,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default user_view;
    
    
    