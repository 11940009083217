import { getAction } from "connected-react-router";
import * as areaActionType from "../../actions/action.area/action.type";


const area = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallarea_error: null,
    getallarea_status: null,

    // area

    create_area: null,
    get_all_area: [],
     all_area: null,
     area_by_id: [],
     area_by_id_loading: false,
     area_by_id_error: null,
     area_enable_disable_status: null,
     update_area_details: null,

     // city-area 
     city_area_by_id: [],
     city_area_by_id_loading: false,
     city_area_by_id_error: null,
    },
    action
) =>{

    switch (action.type) 
    {
    
        //area reducers type
        //create new area
        case areaActionType.CREATE_AREA_REQUEST:
          return Object.assign({}, state, 
            {
            status: action.status,
            });
        case areaActionType.CREATE_AREA_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_area: action.result,
          });
        case areaActionType.CREATE_AREA_ERROR:
          return Object.assign({}, state, {
            getallarea_status: action.status,
            getallarea_error: action.error,
          });
         
          // all areas get
          case areaActionType.GET_ALL_AREA_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case areaActionType.GET_ALL_AREA_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_area: action.result,
            });
          case areaActionType.GET_ALL_AREA_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get areas by id 
          case areaActionType.GET_AREA_BY_ID_REQ:
          return Object.assign({}, state, {
            area_by_id: [],
            area_by_id_loading: true,
            area_by_id_error: null,
          });
        case areaActionType.GET_AREA_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            area_by_id: action.payload,
            area_by_id_loading: false,
            area_by_id_error: null,
          });


           // get city-areas by id 
           case areaActionType.GET_CITY_AREA_BY_ID_REQ:
            return Object.assign({}, state, {
              city_area_by_id: [],
              city_area_by_id_loading: true,
              city_area_by_id_error: null,
            });
          case areaActionType.GET_CITY_AREA_BY_ID_SUCCESS:
            return Object.assign({}, state, {
              city_area_by_id: action.payload,
              city_area_by_id_loading: false,
              city_area_by_id_error: null,
            });

        case areaActionType.GET_CITY_AREA_BY_ID_ERR:
          return Object.assign({}, state, {
            city_area_by_id: [],
            city_area_by_id_loading: false,
            city_area_by_id_error: action.payload,
          });

          
          // update enable disable area status 
          case areaActionType.PUT_AREA_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case areaActionType.PUT_AREA_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              area_enable_disable_status:action.payload,
            });
          case areaActionType.PUT_AREA_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE area DETAILS
        case areaActionType.PUT_AREA_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case areaActionType.PUT_AREA_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_area_details: action.result,
          });
        case areaActionType.PUT_AREA_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default area;
    
    
    