import * as callbackrequestActionType from "../../actions/action.callbackrequest/action.type";


const book_on_call = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallbook_on_call_error: null,
    getallbook_on_call_status: null,

    // book_on_call
    get_all_book_on_call: [],
     all_book_on_call: null,

     book_on_call_by_id: [],
     book_on_call_by_id_loading: false,
     book_on_call_by_id_error: null,

    //  put_book_on_call_by_id_status_and_remarks
    put_book_on_call_by_id_status_and_remarks:null,
     
    },
    action
) =>{

    switch (action.type) {

        
          // all book_on_calls get
          case callbackrequestActionType.GET_ALL_BOOK_ON_CALL_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case callbackrequestActionType.GET_ALL_BOOK_ON_CALL_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_book_on_call: action.result,
            });
          case callbackrequestActionType.GET_ALL_BOOK_ON_CALL_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get book_on_calls by id 
          case callbackrequestActionType.GET_BOOK_ON_CALL_BY_ID_REQ:
          return Object.assign({}, state, {
            book_on_call_by_id: [],
            book_on_call_by_id_loading: true,
            book_on_call_by_id_error: null,
          });
        case callbackrequestActionType.GET_BOOK_ON_CALL_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            book_on_call_by_id: action.payload,
            book_on_call_by_id_loading: false,
            book_on_call_by_id_error: null,
          });
        case callbackrequestActionType.GET_BOOK_ON_CALL_BY_ID_ERR:
          return Object.assign({}, state, {
            book_on_call_by_id: [],
            book_on_call_by_id_loading: false,
            book_on_call_by_id_error: action.payload,
          });
          
           // all book_on_calls get
           case callbackrequestActionType.PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case callbackrequestActionType.PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              put_book_on_call_by_id_status_and_remarks: action.result,
            });
          case callbackrequestActionType.PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default book_on_call;
    
    
    