
import * as dashboardActionType  from '../../actions/action.dashboard/action.type';
const dashboard = (
    //initialize initial state
     state = {
        dashboard: [],
        error: null,
        status: null,
         dashboard_sidebar:null,
         dashboardData:null,
         sidebarShow:false,
        //  campaign_count:null,
        //  user_review_count:null,
        //  question_count:null

    },
    action
) => {
 switch (action.type) {
     //dashboard reducers
     case dashboardActionType.DASHBOARD_REQUEST : 
     return Object.assign({}, state, {
         status : action.status
     })
     
     case dashboardActionType.DASHBOARD_FAILURE : 
     return Object.assign(
         {}, 
        state, {
         status : action.status
     }
     )
      
     case dashboardActionType.DASHBOARD_SUCCESS : 
     return Object.assign({}, state, {
         status : action.status,
         dashboard : action.matches,
         dashboard_sidebar: action.result
     })

    
    


   


         // get dashboard data
        case dashboardActionType.GET_DASHBOARD_DATA_REQ : 
     return Object.assign({}, state, {
         status : action.status
     })
     
     case dashboardActionType.GET_DASHBOARD_DATA_ERR : 
     return Object.assign(
         {}, 
        state, {
         status : action.status
     }
     )
      
     case dashboardActionType.GET_DASHBOARD_DATA_SUCC : 
     return Object.assign({}, state, {
         status : action.status,
         dashboardData: action.result
     })

     // side bar show 
    //  case dashboardActionType.SET_SIDE_BAR_REQ : 
    //  return Object.assign({}, state, {
    //      status : action.status,
    //      sidebarShow: false
    //  })
     case dashboardActionType.SET_SIDE_BAR : 
     return Object.assign({}, state, {
         status : action.status,
         sidebarShow: action.result
     })

     case dashboardActionType.set : 
     return Object.assign({}, state, {
         status : action.status,
         sidebarShow: action.val
     })
    //default behavior
     default : 
     return state;
 };

}

export default dashboard;

