//GET ALL BOOK_ON_CALL
export const GET_ALL_BOOK_ON_CALL_REQ = "GET_ALL_BOOK_ON_CALL_REQ";
export const GET_ALL_BOOK_ON_CALL_ERR = "GET_ALL_BOOK_ON_CALL_ERR";
export const GET_ALL_BOOK_ON_CALL_SUCC = "GET_ALL_BOOK_ON_CALL_SUCC";

//get BOOK_ON_CALL by id
export const GET_BOOK_ON_CALL_BY_ID_REQ = "GET_BOOK_ON_CALL_BY_ID_REQ";
export const GET_BOOK_ON_CALL_BY_ID_ERR = "GET_BOOK_ON_CALL_BY_ID_ERR";
export const GET_BOOK_ON_CALL_BY_ID_SUCCESS = "GET_BOOK_ON_CALL_BY_ID_SUCCESS";

// ENABLE_DISABLE AND REMARKS UPDATE
export const PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_REQ = "PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_REQ";
export const PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_ERR = "PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_ERR";
export const PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_SUCC = "PUT_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS_SUCC";