import { getAction } from "connected-react-router";
import * as feedbackActionType from "../../actions/action.feedback/action.type";


const feedback = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallfeedback_error: null,
    getallfeedback_status: null,

    // feedback

    create_feedback: null,
    get_all_feedback: [],
     all_feedback: null,

     feedback_by_id: [],
     feedback_by_id_loading: false,
     feedback_by_id_error: null,
     feedback_enable_disable_status: null,
     update_feedback_details: null,


     detailed_ratings_of_question_by_feedback_id: [],
     detailed_ratings_of_question_by_feedback_id_loading: false,
     detailed_ratings_of_question_by_feedback_id_error: null,


    //  user rating feedback

    get_all_user_rating_feedback: [],
     all_user_rating_feedback: null,

     all_user_rating_feedback_by_order_id: [],
     all_user_rating_feedback_by_order_id_loading: false,
     all_user_rating_feedback_by_order_id_error: null,
    },
    action
) =>{

    switch (action.type) {
    
        //feedback reducers type
        
    
        //create new feedback
        case feedbackActionType.CREATE_FEEDBACK_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case feedbackActionType.CREATE_FEEDBACK_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_feedback: action.result,
          });
        case feedbackActionType.CREATE_FEEDBACK_ERROR:
          return Object.assign({}, state, {
            getallfeedback_status: action.status,
            getallfeedback_error: action.error,
          });
         
          // all feedbacks get
          case feedbackActionType.GET_ALL_FEEDBACK_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case feedbackActionType.GET_ALL_FEEDBACK_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_feedback: action.result,
            });
          case feedbackActionType.GET_ALL_FEEDBACK_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get feedbacks by id 
          case feedbackActionType.GET_FEEDBACK_BY_ID_REQ:
          return Object.assign({}, state, {
            feedback_by_id: [],
            feedback_by_id_loading: true,
            feedback_by_id_error: null,
          });
        case feedbackActionType.GET_FEEDBACK_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            feedback_by_id: action.payload,
            feedback_by_id_loading: false,
            feedback_by_id_error: null,
          });
        case feedbackActionType.GET_FEEDBACK_BY_ID_ERR:
          return Object.assign({}, state, {
            feedback_by_id: [],
            feedback_by_id_loading: false,
            feedback_by_id_error: action.payload,
          });
          // update enable disable feedback status 
          case feedbackActionType.PUT_FEEDBACK_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case feedbackActionType.PUT_FEEDBACK_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              feedback_enable_disable_status:action.payload,
            });
          case feedbackActionType.PUT_FEEDBACK_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE feedback DETAILS
        case feedbackActionType.PUT_FEEDBACK_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case feedbackActionType.PUT_FEEDBACK_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_feedback_details: action.result,
          });
        case feedbackActionType.PUT_FEEDBACK_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });


          // get_detailed_ratings_of_question_by_feedback_id 
          case feedbackActionType.GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_REQ:
          return Object.assign({}, state, {
            detailed_ratings_of_question_by_feedback_id: [],
            detailed_ratings_of_question_by_feedback_id_loading: true,
            detailed_ratings_of_question_by_feedback_id_error: null,
          });
        case feedbackActionType.GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_SUCCESS:
          return Object.assign({}, state, {
            detailed_ratings_of_question_by_feedback_id: action.payload,
            detailed_ratings_of_question_by_feedback_id_loading: false,
            detailed_ratings_of_question_by_feedback_id_error: null,
          });
        case feedbackActionType.GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_ERR:
          return Object.assign({}, state, {
            detailed_ratings_of_question_by_feedback_id: [],
            detailed_ratings_of_question_by_feedback_id_loading: false,
            detailed_ratings_of_question_by_feedback_id_error: action.payload,
          });







          // user feedback rating 

           // all user rating feedbacks get
           case feedbackActionType.GET_ALL_USER_RATING_FEEDBACK_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case feedbackActionType.GET_ALL_USER_RATING_FEEDBACK_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_user_rating_feedback: action.result,
            });
          case feedbackActionType.GET_ALL_USER_RATING_FEEDBACK_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // all_user_rating_feedback_by_order_id
          case feedbackActionType.GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_REQ:
          return Object.assign({}, state, {
            all_user_rating_feedback_by_order_id: [],
            all_user_rating_feedback_by_order_id_loading: true,
            all_user_rating_feedback_by_order_id_error: null,
          });
        case feedbackActionType.GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_SUCC:
          return Object.assign({}, state, {
            all_user_rating_feedback_by_order_id: action.payload,
            all_user_rating_feedback_by_order_id_loading: false,
            all_user_rating_feedback_by_order_id_error: null,
          });
        case feedbackActionType.GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_ERR:
          return Object.assign({}, state, {
            all_user_rating_feedback_by_order_id: [],
            all_user_rating_feedback_by_order_id_loading: false,
            all_user_rating_feedback_by_order_id_error: action.payload,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default feedback;
    
    
    