import { 
  login_req, 
  login_fail, 
  login_success, 
  admin_purge_req,
  admin_purge_fail,
  admin_purge_success
  
} from './action.set.type';
import { 
  API_URL,
  login_user,  
  } from '../../api/api';

import decodeJwt from "jwt-decode";

import { 
    ADD_TOKEN_TO_STATE, 
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    ADMIN_PURGE_REQUEST,
    ADMIN_PURGE_SUCCESS,
    QUERYING_USER_BY_TOKEN,
    QUERYING_USER_BY_TOKEN_ERROR, 
    QUERYING_USER_BY_TOKEN_SUCCESS 
} from './action.type';

import {  post} from '../../../utils/apiRequest';
import {API} from '../../../utils/networkApi';

import { push } from "connected-react-router"
import { toast } from "react-toastify"

import errorPage from '../../../utils/RedirectErrorPage';
import toaster from "../../../utils/ReactTostify";
import {isLoading} from "../action.loader/action.set.type.js";
import {persistor} from '../../store/create.store';
import {LOG_IN_FAIL_MESSAGE} from '../constance/action.constance.js'

export function createUser(payload) {
  return async (dispatch, getState) => {
    dispatch()
  }
}

// login for admin

export const login = (payload,callback) => {
  return async (dispatch, getState) => {
    dispatch(login_req());
    dispatch(isLoading(true));
    try {
      const { email, password } = payload;
      // const result = await post(login_user, { username:email, password:password });
      const result = await API.post(API_URL.LOGIN, { username:email, password:password });
      dispatch(isLoading(false));
      // const  user  = await result.json();
      const  user  = await result.data;
      
      //  console.log("user values === ", user);

      if(user.message == LOG_IN_FAIL_MESSAGE){
        toaster('error', 'Invalid credentials or no data found');
      }
      else{
        localStorage.setItem("token", user.token);
        dispatch(addTokenToState());
        dispatch(login_success(user));
        callback(null,user);
        toaster('success', 'Logged in successfully');
      }
      
        
      // else if((result.status === 401)){
      //   //const error = await result.json();
      //   dispatch(login_fail(user))
      //   toaster('error', "Login failed");
      // }
      // else if((result.status === 400)){
      //   //const error = await result.json();
      //   dispatch(login_fail(user))
      //   errorPage('400');
      // }

      // else if((result.status === 404)){
      //   //const error = await result.json();
      //   dispatch(login_fail(user))
      //   errorPage('404');
      // }

      // else if((result.status === 500)){
      //   //const error = await result.json();
      //   dispatch(login_fail(user))
      //   errorPage('500');
      // }
      
    } catch (err) {
      dispatch(isLoading(false));
      dispatch(login_fail(err));
      callback(err,null);
      // toaster('error', err);
    }
  }
}

//logout admin
export const logout = () => dispatch => {
  // log out backwoods
  
  // unlink third-party account
  dispatch({ type: LOGOUT_REQUEST });
  dispatch(isLoading(true));
  try{
    localStorage.removeItem('token');
    persistor.purge();
    dispatch(isLoading(false));
    dispatch({ type: LOGOUT_SUCCESS })

    dispatch(push("/login"))
    //localStorage.removeItem("token")
    toast.success("Log out successful", {
      position: toast.POSITION.BOTTOM_RIGHT
    })
    
  }
  catch(err) {
    console.log(err)
    dispatch(isLoading(false));
    toast.error("Error when unlink your account", {
      position: toast.POSITION.BOTTOM_RIGHT
    })
  }
  dispatch(push("/login"));
}


// purge admin
export const admin_purge = () => dispatch => {
  
 // unlink third-party account
  dispatch({ type: ADMIN_PURGE_REQUEST });
  try{
    localStorage.removeItem('token');
    persistor.purge();
    dispatch({ type: ADMIN_PURGE_SUCCESS })

    dispatch(push("/login"))
    //localStorage.removeItem("token")
    
    
  }
  catch(err) {
    console.log(err)
    toast.error("Error when unlink your account", {
      position: toast.POSITION.BOTTOM_RIGHT
    })
  }
  dispatch(push("/login"));
}


export const addTokenToState = () => dispatch => {
  let token
  try {
    token = localStorage.getItem("token")
  } catch (e) {
    console.log("ADD TOKEN TO STATE ERROR:", e)
  }
  // If no token, bail out:
  if (!token) return
  dispatch({ type: ADD_TOKEN_TO_STATE, payload: token })
  // Use token to check DB for user:
  dispatch(checkDbForUser(token))
}




// admin access based on adminid
export const checkDbForUser = token => dispatch => {
  dispatch({ type: QUERYING_USER_BY_TOKEN })
  const { userId, exp } = decodeJwt(token)
  if (!userId)
    return dispatch({
      type: QUERYING_USER_BY_TOKEN_ERROR,
      payload: `Token invalid: ${token}`
    })
  if (Date.now() >= exp * 6) {
    console.log('--------token and id-----',token, userId);
    dispatch(push("/login"))
  }
console.log('--------token and id-----',token, userId)
  // getWithAuth(getUserById(id), {
  //   headers: {
  //     Authorization: `Bearer ${token}`
  //   }
  // }).then(res => res.json())
  //   .then(response => {
  //      console.log('-------------get OOOOOOOO-----', response)
  //     dispatch({ type: QUERYING_USER_BY_TOKEN_SUCCESS, payload: response })
  //     dispatch(push("/dashboard"))
      
  //   })
  //   .catch(err => {
  //     dispatch({
  //       type: QUERYING_USER_BY_TOKEN_ERROR,
  //       payload: err
  //     })
  //     toast.error(err, {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     })
  //   })
}

