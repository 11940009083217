// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_FEEDBACK_REQUEST = "CREATE_FEEDBACK_REQUEST";
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS";
export const CREATE_FEEDBACK_ERROR = "CREATE_FEEDBACK_ERROR";

//GET ALL  FEEDBACK
export const GET_ALL_FEEDBACK_REQ = "GET_ALL_FEEDBACK_REQ";
export const GET_ALL_FEEDBACK_ERR = "GET_ALL_FEEDBACK_ERR";
export const GET_ALL_FEEDBACK_SUCC = "GET_ALL_FEEDBACK_SUCC";

//get FEEDBACK by id
export const GET_FEEDBACK_BY_ID_REQ = "GET_FEEDBACK_BY_ID_REQ";
export const GET_FEEDBACK_BY_ID_ERR = "GET_FEEDBACK_BY_ID_ERR";
export const GET_FEEDBACK_BY_ID_SUCCESS = "GET_FEEDBACK_BY_ID_SUCCESS";

// ENABLE_DISABLE FEEDBACK
export const PUT_FEEDBACK_STATUS_BY_ID_REQ = "PUT_FEEDBACK_STATUS_BY_ID_REQ";
export const PUT_FEEDBACK_STATUS_BY_ID_ERR = "PUT_FEEDBACK_STATUS_BY_ID_ERR";
export const PUT_FEEDBACK_STATUS_BY_ID_SUCC = "PUT_FEEDBACK_STATUS_BY_ID_SUCC";


//UPDATE FEEDBACK DETAILS
export const PUT_FEEDBACK_DETAILS_REQ = "PUT_FEEDBACK_DETAILS_REQ";
export const PUT_FEEDBACK_DETAILS_SUCC = "PUT_FEEDBACK_DETAILS_SUCC";
export const PUT_FEEDBACK_DETAILS_ERR = "PUT_FEEDBACK_DETAILS_ERR";

// GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID
export const GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_REQ = "GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_REQ";
export const GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_ERR = "GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_ERR";
export const GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_SUCCESS = "GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID_SUCCESS";





// User ratings feedback

//GET ALL USER RATING FEEDBACK
export const GET_ALL_USER_RATING_FEEDBACK_REQ = "GET_ALL_USER_RATING_FEEDBACK_REQ";
export const GET_ALL_USER_RATING_FEEDBACK_ERR = "GET_ALL_USER_RATING_FEEDBACK_ERR";
export const GET_ALL_USER_RATING_FEEDBACK_SUCC = "GET_ALL_USER_RATING_FEEDBACK_SUCC";

//GET ALL USER RATING FEEDBACK BY ORDER ID
export const GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_REQ = "GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_REQ";
export const GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_ERR = "GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_ERR";
export const GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_SUCC = "GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID_SUCC";






