import * as familymemberActionType from "../../actions/action.familymembers/action.type";


const familymember = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallfamilymember_error: null,
    getallfamilymember_status: null,

    // familymember

    create_familymember: null,
    create_familymember_modal: null,

    create_familymember_address: null,

    get_all_familymember_address: [],
     all_familymember_address: null,

    get_all_familymember: [],
     all_familymember: null,

     familymember_by_id: [],
     familymember_by_id_loading: false,
     familymember_by_id_error: null,
     familymember_enable_disable_status: null,
     update_familymember_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //familymember reducers type
        
    
        //create new familymember
        case familymemberActionType.CREATE_FAMILYMEMBER_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case familymemberActionType.CREATE_FAMILYMEMBER_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_familymember: action.result,
          });
        case familymemberActionType.CREATE_FAMILYMEMBER_ERROR:
          return Object.assign({}, state, {
            getallfamilymember_status: action.status,
            getallfamilymember_error: action.error,
          });


              //create new familymember modal
        case familymemberActionType.CREATE_FAMILYMEMBER_MODAL_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case familymemberActionType.CREATE_FAMILYMEMBER_MODAL_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_familymember_modal: action.result,
          });
        case familymemberActionType.CREATE_FAMILYMEMBER_MODAL_ERROR:
          return Object.assign({}, state, {
            getallfamilymember_status: action.status,
            getallfamilymember_error: action.error,
          });
         
          // all familymembers get
          case familymemberActionType.GET_ALL_FAMILYMEMBER_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case familymemberActionType.GET_ALL_FAMILYMEMBER_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_familymember: action.result,
            });
          case familymemberActionType.GET_ALL_FAMILYMEMBER_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get familymembers by id 
          case familymemberActionType.GET_FAMILYMEMBER_BY_ID_REQ:
          return Object.assign({}, state, {
            familymember_by_id: [],
            familymember_by_id_loading: true,
            familymember_by_id_error: null,
          });
        case familymemberActionType.GET_FAMILYMEMBER_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            familymember_by_id: action.payload,
            familymember_by_id_loading: false,
            familymember_by_id_error: null,
          });
        case familymemberActionType.GET_FAMILYMEMBER_BY_ID_ERR:
          return Object.assign({}, state, {
            familymember_by_id: [],
            familymember_by_id_loading: false,
            familymember_by_id_error: action.payload,
          });
          // update enable disable familymember status 
          case familymemberActionType.PUT_FAMILYMEMBER_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case familymemberActionType.PUT_FAMILYMEMBER_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              familymember_enable_disable_status:action.payload,
            });
          case familymemberActionType.PUT_FAMILYMEMBER_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE familymember DETAILS
        case familymemberActionType.PUT_FAMILYMEMBER_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case familymemberActionType.PUT_FAMILYMEMBER_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_familymember_details: action.result,
          });
        case familymemberActionType.PUT_FAMILYMEMBER_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });


          //create new familymember address
        case familymemberActionType.CREATE_FAMILYMEMBER_ADDRESS_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case familymemberActionType.CREATE_FAMILYMEMBER_ADDRESS_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_familymember_address: action.result,
          });
        case familymemberActionType.CREATE_FAMILYMEMBER_ADDRESS_ERROR:
          return Object.assign({}, state, {
            getallfamilymember_status: action.status,
            getallfamilymember_error: action.error,
          });

           // all familymembers get
           case familymemberActionType.GET_ALL_FAMILYMEMBER_ADDRESS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case familymemberActionType.GET_ALL_FAMILYMEMBER_ADDRESS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_familymember_address: action.result,
            });
          case familymemberActionType.GET_ALL_FAMILYMEMBER_ADDRESS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default familymember;
    
    
    