import * as getALlorderActionType from "../../actions/action.ordergraph/action.ordergraph.type";


const allOrder_graph = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
 
    // order
    get_order_date:null,
    get_order:null,
    get_processed_order:null,
    get_cancel_order:null,
    get_closed_order:null,



    },
    action
) =>{

    switch (action.type) {
    
        //order_date reducers type
        
        case getALlorderActionType.GET_ORDER_DATE_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case getALlorderActionType.GET_ORDER_DATE_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            get_order_date: action.result,
          });
        case getALlorderActionType.GET_ORDER_DATE_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
      
         
          // all orders get
          case getALlorderActionType.GET_ORDER_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case getALlorderActionType.GET_ORDER_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              get_order: action.result,
            });
          case getALlorderActionType.GET_ORDER_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
            // GET_ORDER_PROCESSED_REQ

            case getALlorderActionType.GET_ORDER_PROCESSED_REQ:
                return Object.assign({}, state, {
                  status: action.status,
                });
              case getALlorderActionType.GET_ORDER_PROCESSED_SUCC:
                return Object.assign({}, state, {
                  status: action.status,
                  get_processed_order: action.result,
                });
              case getALlorderActionType.GET_ORDER_PROCESSED_ERR:
                return Object.assign({}, state, {
                  status: action.status,
                  error: action.error,
                });

                //GET_CANCEL_ORDER_REQ 
                case getALlorderActionType.GET_CANCEL_ORDER_REQ:
                    return Object.assign({}, state, {
                      status: action.status,
                    });
                  case getALlorderActionType.GET_CANCEL_ORDER_SUCC:
                    return Object.assign({}, state, {
                      status: action.status,
                      get_cancel_order: action.result,
                    });
                  case getALlorderActionType.GET_CANCEL_ORDER_ERR:
                    return Object.assign({}, state, {
                      status: action.status,
                      error: action.error,
                    });

                    // GET_CLOSED_ORDER_REQ
                    case getALlorderActionType.GET_CLOSED_ORDER_REQ:
                        return Object.assign({}, state, {
                          status: action.status,
                        });
                      case getALlorderActionType.GET_CLOSED_ORDER_SUCC:
                        return Object.assign({}, state, {
                          status: action.status,
                          get_closed_order: action.result,
                        });
                      case getALlorderActionType.GET_CLOSED_ORDER_ERR:
                        return Object.assign({}, state, {
                          status: action.status,
                          error: action.error,
                        });
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default allOrder_graph ;
    
    
    