const preciseError = async (type, error) => {
    return new Promise((resolve, reject) => {
      try{
        var message;
          if(type==='success' || 'info'){
            message = error;
          }
          if(type==="error"){
            if(typeof(error)=="object"){
                if(error.at || error.message || error.msg){
                const showm = error.at || error.message || error.msg;
                message = showm;
               }
               else{
                message = error;
               }
            }else{
              message = error;
             }
          }
               resolve(message);
        }catch(error){
            reject({error:error});
        }
        });
  }; 

  export default preciseError;