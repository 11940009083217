import axios from 'axios'
import errorPage from './RedirectErrorPage';
// import {isLoading} from "../action.loader/action.set.type.js";
import {isLoading} from "../Redux-service/actions/action.loader/action.set.type.js";
import { useHistory } from 'react-router-dom';
import toaster from './ReactTostify.js';
import { createBrowserHistory } from "history";
// const token = localStorage.getItem("auth_token");
// const _headers = {
//   Authorization: "Token " + token,
//   "Content-Type": "application/json"
// };

const history = createBrowserHistory();
export const API = {
    getHeaders: () => {
        let token = localStorage.getItem("token");
        let headers = {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
          
        };
        return headers;
      },

      getHeadersFormData: () => {
        let token = localStorage.getItem("token");
        let headersformdata = {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + token,
          // "Content-Type": "application/json"
        };
        return headersformdata;
      },
    
  get: (route, headers) => {
    const getPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, null, 'get', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return getPromise;
  },
  post: (route, params, headers) => {
    const postPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, params, 'post', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return postPromise;
  },

  put: (route, params, headers, callback) => API.prepareConfig(route, params, 'put', headers, callback),
  delete: (route, headers) => {
    const getPromise = new Promise((resolve, reject) => {
      API.prepareConfig(route, null, 'delete', headers, 
      (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    });
    return getPromise;
  },

  prepareConfig: async (routeurl, params, methodType, headers={}, callback) => {
    const config = {
      method: methodType,
      url: routeurl,
      data: params,
      headers: headers,
      // timeout: 2000,
      // timeout: API.requesttimeout,
    };
    API.call(config, callback);
  },

  /*
    * This method will take URL path as string for "routeurl"
    * params will take body: {key: value}, note: not stringify
    * Header:  {key: value}, note: not stringify
    * callback will give us two params (error, responseJson),
      so check for error not equal to null then process json
      else throw alert in your UI by using error parameter.
  */

  call: (config, callback) => {

    axios(config)
      .then((response) => {
        console.log("response axios ======", response);
        callback(null, response);
      })
      .catch((error) => {
        // const history = useHistory();
          console.log("error axios ======", error);
           console.log("error axios data ======", error.data);

          if(((error.response.data.message === "Network Error") && !error.response))
          {
            console.log("error axios data!!!!!!!",error.response.data.message);
            isLoading(false);
            const errordata = {
              response:{
                status:500
              }
            }
            isLoading(false);
            errorPage(errordata,config.url);
            history.push('/login');
            toaster('error', "You have been logged out,kindly login again"); 
          }
          else{
            isLoading(false);
            errorPage(error,config.url);
          }


          
     
          isLoading(false);
        callback(error, null);

        throw error;
      });
  },

}
