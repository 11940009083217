import { getAction } from "connected-react-router";
import * as reschedule_order_timeslotActionType from "../../actions/action.reschedule/action.type";


const order_reschedule = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    reschedule_order_error: null,
    reschedule_order_status: null,

    // timeslot

    reschedule_order_timeslot: null,
  

    
    },
    action
) =>{

    switch (action.type) {
    
        //timeslot reducers type
        
    
        //create new timeslot
        case reschedule_order_timeslotActionType .POST_RESCHEDULE_TIMESLOT_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case reschedule_order_timeslotActionType .POST_RESCHEDULE_TIMESLOT_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            reschedule_order_timeslot: action.result,
          });
        case reschedule_order_timeslotActionType .POST_RESCHEDULE_TIMESLOT_ERROR:
          return Object.assign({}, state, {
            reschedule_order_status: action.status,
            reschedule_order_error: action.error,
          });
         
         
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default order_reschedule;
    
    
    