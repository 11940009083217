import * as orderActionType from "../../actions/action.orders/action.type";


const order = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallorder_error: null,
    getallorder_status: null,

    // order

    create_order: null,
    get_all_order: [],
     all_order: null,

     order_by_id: [],
     order_by_id_loading: false,
     order_by_id_error: null,
     order_enable_disable_status: null,
     update_order_details: null,
     assign_technician_by_order_id:null,
     get_assign_technician_by_order_id:null,
     update_assign_technician_by_order_id:null,
     cancel_order_by_order_id:null,

     // refundable orders
     get_all_refundable_order: [],
     all_refundable_order: null,
     post_refundable_refund: null,
    },
    action
) =>{

    switch (action.type) {
    
        //order reducers type
        
    
        //create new order
        case orderActionType.CREATE_ORDER_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case orderActionType.CREATE_ORDER_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_order: action.result,
          });
        case orderActionType.CREATE_ORDER_ERROR:
          return Object.assign({}, state, {
            getallorder_status: action.status,
            getallorder_error: action.error,
          });
         
          // all orders get
          case orderActionType.GET_ALL_ORDER_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case orderActionType.GET_ALL_ORDER_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_order: action.result,
            });
          case orderActionType.GET_ALL_ORDER_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get orders by id 
          case orderActionType.GET_ORDER_BY_ID_REQ:
          return Object.assign({}, state, {
            order_by_id: [],
            order_by_id_loading: true,
            order_by_id_error: null,
          });
        case orderActionType.GET_ORDER_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            order_by_id: action.payload,
            order_by_id_loading: false,
            order_by_id_error: null,
          });
        case orderActionType.GET_ORDER_BY_ID_ERR:
          return Object.assign({}, state, {
            order_by_id: [],
            order_by_id_loading: false,
            order_by_id_error: action.payload,
          });
          // update enable disable order status 
          case orderActionType.PUT_ORDER_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case orderActionType.PUT_ORDER_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              order_enable_disable_status:action.payload,
            });
          case orderActionType.PUT_ORDER_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE order DETAILS
        case orderActionType.PUT_ORDER_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case orderActionType.PUT_ORDER_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_order_details: action.result,
          });
        case orderActionType.PUT_ORDER_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });

          // Post Assign Technician by ORDER id 
          case orderActionType.POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ:
          return Object.assign({}, state, {
            assign_technician_by_order_id:null,
            
          });
        case orderActionType.POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS:
          return Object.assign({}, state, {
            assign_technician_by_order_id: action.payload,
           
          });
        case orderActionType.POST_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR:
          return Object.assign({}, state, {
            assign_technician_by_order_id: action.payload,
          });


          // Get Assign Technician by ORDER id 
          case orderActionType.GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ:
          return Object.assign({}, state, {
            get_assign_technician_by_order_id:null,
            
          });
        case orderActionType.GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS:
          return Object.assign({}, state, {
            get_assign_technician_by_order_id: action.payload,
           
          });
        case orderActionType.GET_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR:
          return Object.assign({}, state, {
            get_assign_technician_by_order_id: action.payload,
          });


           // update Assign Technician by ORDER id 
           case orderActionType.UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_REQ:
            return Object.assign({}, state, {
              update_assign_technician_by_order_id:null,
              
            });
          case orderActionType.UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_SUCCESS:
            return Object.assign({}, state, {
              update_assign_technician_by_order_id: action.payload,
             
            });
          case orderActionType.UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID_ERR:
            return Object.assign({}, state, {
              update_assign_technician_by_order_id: action.payload,
            });
    

            // CANCEL_ORDER_BY_ORDER_ID 
          case orderActionType.CANCEL_ORDER_BY_ORDER_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case orderActionType.CANCEL_ORDER_BY_ORDER_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              cancel_order_by_order_id:action.payload,
            });
          case orderActionType.CANCEL_ORDER_BY_ORDER_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


              // all refundable orders get
          case orderActionType.GET_ALL_REFUNDABLE_ORDER_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case orderActionType.GET_ALL_REFUNDABLE_ORDER_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_refundable_order: action.result,
            });
          case orderActionType.GET_ALL_REFUNDABLE_ORDER_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });


               // POST_REFUNDABLE_ORDER_BY_ORDER_ID
        case orderActionType.POST_REFUNDABLE_ORDER_BY_ORDER_ID_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case orderActionType.PUT_ORDER_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            post_refundable_refund: action.result,
          });
        case orderActionType.POST_REFUNDABLE_ORDER_BY_ORDER_ID_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default order;
    
    
    