import { getAction } from "connected-react-router";
import * as cityActionType from "../../actions/action.city/action.type";


const city = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallcity_error: null,
    getallcity_status: null,

    // city

    create_city: null,
    get_all_city: [],
     all_city: null,

     city_by_id: [],
     city_by_id_loading: false,
     city_by_id_error: null,
     city_enable_disable_status: null,
     update_city_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //city reducers type
        
    
        //create new city
        case cityActionType.CREATE_CITY_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case cityActionType.CREATE_CITY_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_city: action.result,
          });
        case cityActionType.CREATE_CITY_ERROR:
          return Object.assign({}, state, {
            getallcity_status: action.status,
            getallcity_error: action.error,
          });
         
          // all citys get
          case cityActionType.GET_ALL_CITY_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case cityActionType.GET_ALL_CITY_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_city: action.result,
            });
          case cityActionType.GET_ALL_CITY_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get citys by id 
          case cityActionType.GET_CITY_BY_ID_REQ:
          return Object.assign({}, state, {
            city_by_id: [],
            city_by_id_loading: true,
            city_by_id_error: null,
          });
        case cityActionType.GET_CITY_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            city_by_id: action.payload,
            city_by_id_loading: false,
            city_by_id_error: null,
          });
        case cityActionType.GET_CITY_BY_ID_ERR:
          return Object.assign({}, state, {
            city_by_id: [],
            city_by_id_loading: false,
            city_by_id_error: action.payload,
          });
          // update enable disable city status 
          case cityActionType.PUT_CITY_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case cityActionType.PUT_CITY_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              city_enable_disable_status:action.payload,
            });
          case cityActionType.PUT_CITY_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE city DETAILS
        case cityActionType.PUT_CITY_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case cityActionType.PUT_CITY_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_city_details: action.result,
          });
        case cityActionType.PUT_CITY_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default city;
    
    
    