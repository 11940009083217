import { getAction } from "connected-react-router";
import * as articleActionType from "../../actions/action.article/action.type";


const article = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallarticle_error: null,
    getallarticle_status: null,

    // article

    create_article: null,
    get_all_article: [],
     all_article: null,

     article_by_id: [],
     article_by_id_loading: false,
     article_by_id_error: null,
     article_enable_disable_status: null,
     update_article_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //article reducers type
        
    
        //create new article
        case articleActionType.CREATE_ARTICLE_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case articleActionType.CREATE_ARTICLE_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_article: action.result,
          });
        case articleActionType.CREATE_ARTICLE_ERROR:
          return Object.assign({}, state, {
            getallarticle_status: action.status,
            getallarticle_error: action.error,
          });
         
          // all articles get
          case articleActionType.GET_ALL_ARTICLE_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case articleActionType.GET_ALL_ARTICLE_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_article: action.result,
            });
          case articleActionType.GET_ALL_ARTICLE_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get articles by id 
          case articleActionType.GET_ARTICLE_BY_ID_REQ:
          return Object.assign({}, state, {
            article_by_id: [],
            article_by_id_loading: true,
            article_by_id_error: null,
          });
        case articleActionType.GET_ARTICLE_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            article_by_id: action.payload,
            article_by_id_loading: false,
            article_by_id_error: null,
          });
        case articleActionType.GET_ARTICLE_BY_ID_ERR:
          return Object.assign({}, state, {
            article_by_id: [],
            article_by_id_loading: false,
            article_by_id_error: action.payload,
          });
          // update enable disable article status 
          case articleActionType.PUT_ARTICLE_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case articleActionType.PUT_ARTICLE_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              article_enable_disable_status:action.payload,
            });
          case articleActionType.PUT_ARTICLE_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE article DETAILS
        case articleActionType.PUT_ARTICLE_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case articleActionType.PUT_ARTICLE_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_article_details: action.result,
          });
        case articleActionType.PUT_ARTICLE_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default article;
    
    
    