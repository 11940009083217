// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_SUPPORT_REQUEST = "CREATE_SUPPORT_REQUEST";
export const CREATE_SUPPORT_SUCCESS = "CREATE_SUPPORT_SUCCESS";
export const CREATE_SUPPORT_ERROR = "CREATE_SUPPORT_ERROR";

//GET ALL  SUPPORT
export const GET_ALL_SUPPORT_REQ = "GET_ALL_SUPPORT_REQ";
export const GET_ALL_SUPPORT_ERR = "GET_ALL_SUPPORT_ERR";
export const GET_ALL_SUPPORT_SUCC = "GET_ALL_SUPPORT_SUCC";

//get SUPPORT by id
export const GET_SUPPORT_BY_TICKET_ID_REQ = "GET_SUPPORT_BY_TICKET_ID_REQ";
export const GET_SUPPORT_BY_TICKET_ID_ERR = "GET_SUPPORT_BY_TICKET_ID_ERR";
export const GET_SUPPORT_BY_TICKET_ID_SUCCESS = "GET_SUPPORT_BY_TICKET_ID_SUCCESS";

// SUPPORT_STATUS_UPDATE
export const PUT_SUPPORT_STATUS_BY_TICKET_ID_REQ = "PUT_SUPPORT_STATUS_BY_TICKET_ID_REQ";
export const PUT_SUPPORT_STATUS_BY_TICKET_ID_ERR = "PUT_SUPPORT_STATUS_BY_TICKET_ID_ERR";
export const PUT_SUPPORT_STATUS_BY_TICKET_ID_SUCC = "PUT_SUPPORT_STATUS_BY_TICKET_ID_SUCC";


//UPDATE SUPPORT DETAILS
export const PUT_SUPPORT_DETAILS_REQ = "PUT_SUPPORT_DETAILS_REQ";
export const PUT_SUPPORT_DETAILS_SUCC = "PUT_SUPPORT_DETAILS_SUCC";
export const PUT_SUPPORT_DETAILS_ERR = "PUT_SUPPORT_DETAILS_ERR";


// get support conversation by ticket id 
export const GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ = "GET_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ";
export const GET_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR = "GET_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR";
export const GET_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS = "GET_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS";


// CREATE support conversation by ticket id 
export const CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ = "CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_REQ";
export const CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR = "CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_ERR";
export const CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS = "CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID_SUCCESS";

