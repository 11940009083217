export const config_file = {
  development: {
    
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
     API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    // API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
  },
  production: {
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",


    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    // API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
  },
  staging: {
    // DOMAIN_NAME: "https://your.xyz.com",
    // API_DOMAIN_NAME: "https://alpha.xyz.com",
    
    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    // API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
  },
  test: {
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "https://lab.landclues.com/backend",
    // API_DOMAIN_NAME: "https://pathocare.webschedio.in/backend",
    GOOGLE_MAP_API_KEY:"AIzaSyC-uu7hWY6Nx9AqvHM9sTmEG6w9qeJgZAg",
  },
};
