// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";




// For perKilometerCharge  [PER_KILOMETER_CHARGE]

// CREATE PER_KILOMETER_CHARGE
export const CREATE_PER_KILOMETER_CHARGE_REQUEST = "CREATE_PER_KILOMETER_CHARGE_REQUEST";
export const CREATE_PER_KILOMETER_CHARGE_SUCCESS = "CREATE_PER_KILOMETER_CHARGE_SUCCESS";
export const CREATE_PER_KILOMETER_CHARGE_ERROR = "CREATE_PER_KILOMETER_CHARGE_ERROR";

//GET PER_KILOMETER_CHARGE
export const GET_PER_KILOMETER_CHARGE_REQ = "GET_PER_KILOMETER_CHARGE_REQ";
export const GET_PER_KILOMETER_CHARGE_ERR = "GET_PER_KILOMETER_CHARGE_ERR";
export const GET_PER_KILOMETER_CHARGE_SUCC = "GET_PER_KILOMETER_CHARGE_SUCC";


//UPDATE PER_KILOMETER_CHARGE
export const PUT_PER_KILOMETER_CHARGE_DETAILS_REQ = "PUT_PER_KILOMETER_CHARGE_DETAILS_REQ";
export const PUT_PER_KILOMETER_CHARGE_DETAILS_SUCC = "PUT_PER_KILOMETER_CHARGE_DETAILS_SUCC";
export const PUT_PER_KILOMETER_CHARGE_DETAILS_ERR = "PUT_PER_KILOMETER_CHARGE_DETAILS_ERR";


//--------------------------------------//

// For penaltyAmountCancellingOrder  [PENALTY_AMOUNT_CANCELLING_ORDER]

// CREATE PENALTY_AMOUNT_CANCELLING_ORDER
export const CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_REQUEST = "CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_REQUEST";
export const CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_SUCCESS = "CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_SUCCESS";
export const CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_ERROR = "CREATE_PENALTY_AMOUNT_CANCELLING_ORDER_ERROR";

//GET PENALTY_AMOUNT_CANCELLING_ORDER
export const GET_PENALTY_AMOUNT_CANCELLING_ORDER_REQ = "GET_PENALTY_AMOUNT_CANCELLING_ORDER_REQ";
export const GET_PENALTY_AMOUNT_CANCELLING_ORDER_ERR = "GET_PENALTY_AMOUNT_CANCELLING_ORDER_ERR";
export const GET_PENALTY_AMOUNT_CANCELLING_ORDER_SUCC = "GET_PENALTY_AMOUNT_CANCELLING_ORDER_SUCC";


//UPDATE PENALTY_AMOUNT_CANCELLING_ORDER
export const PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_REQ = "PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_REQ";
export const PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_SUCC = "PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_SUCC";
export const PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_ERR = "PUT_PENALTY_AMOUNT_CANCELLING_ORDER_DETAILS_ERR";

//---------------------------------------------------//

// For minimumAmountPlacingOrder  [MINIMUM_AMOUNT_PLACING_ORDER]

// CREATE MINIMUM_AMOUNT_PLACING_ORDER
export const CREATE_MINIMUM_AMOUNT_PLACING_ORDER_REQUEST = "CREATE_MINIMUM_AMOUNT_PLACING_ORDER_REQUEST";
export const CREATE_MINIMUM_AMOUNT_PLACING_ORDER_SUCCESS = "CREATE_MINIMUM_AMOUNT_PLACING_ORDER_SUCCESS";
export const CREATE_MINIMUM_AMOUNT_PLACING_ORDER_ERROR = "CREATE_MINIMUM_AMOUNT_PLACING_ORDER_ERROR";

//GET MINIMUM_AMOUNT_PLACING_ORDER
export const GET_MINIMUM_AMOUNT_PLACING_ORDER_REQ = "GET_MINIMUM_AMOUNT_PLACING_ORDER_REQ";
export const GET_MINIMUM_AMOUNT_PLACING_ORDER_ERR = "GET_MINIMUM_AMOUNT_PLACING_ORDER_ERR";
export const GET_MINIMUM_AMOUNT_PLACING_ORDER_SUCC = "GET_MINIMUM_AMOUNT_PLACING_ORDER_SUCC";


//UPDATE MINIMUM_AMOUNT_PLACING_ORDER
export const PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_REQ = "PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_REQ";
export const PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_SUCC = "PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_SUCC";
export const PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_ERR = "PUT_MINIMUM_AMOUNT_PLACING_ORDER_DETAILS_ERR";

//---------------------------------------------------//


// For lateCancellationPenalty  [LATE_CANCELLATION_PENALTY]

// CREATE LATE_CANCELLATION_PENALTY
export const CREATE_LATE_CANCELLATION_PENALTY_REQUEST = "CREATE_LATE_CANCELLATION_PENALTY_REQUEST";
export const CREATE_LATE_CANCELLATION_PENALTY_SUCCESS = "CREATE_LATE_CANCELLATION_PENALTY_SUCCESS";
export const CREATE_LATE_CANCELLATION_PENALTY_ERROR = "CREATE_LATE_CANCELLATION_PENALTY_ERROR";

//GET LATE_CANCELLATION_PENALTY
export const GET_LATE_CANCELLATION_PENALTY_REQ = "GET_LATE_CANCELLATION_PENALTY_REQ";
export const GET_LATE_CANCELLATION_PENALTY_ERR = "GET_LATE_CANCELLATION_PENALTY_ERR";
export const GET_LATE_CANCELLATION_PENALTY_SUCC = "GET_LATE_CANCELLATION_PENALTY_SUCC";


//UPDATE LATE_CANCELLATION_PENALTY
export const PUT_LATE_CANCELLATION_PENALTY_DETAILS_REQ = "PUT_LATE_CANCELLATION_PENALTY_DETAILS_REQ";
export const PUT_LATE_CANCELLATION_PENALTY_DETAILS_SUCC = "PUT_LATE_CANCELLATION_PENALTY_DETAILS_SUCC";
export const PUT_LATE_CANCELLATION_PENALTY_DETAILS_ERR = "PUT_LATE_CANCELLATION_PENALTY_DETAILS_ERR";

//---------------------------------------------------//


// For emergencyRequestCharge  [EMERGENCY_REQUEST_CHARGE]

// CREATE EMERGENCY_REQUEST_CHARGE
export const CREATE_EMERGENCY_REQUEST_CHARGE_REQUEST = "CREATE_EMERGENCY_REQUEST_CHARGE_REQUEST";
export const CREATE_EMERGENCY_REQUEST_CHARGE_SUCCESS = "CREATE_EMERGENCY_REQUEST_CHARGE_SUCCESS";
export const CREATE_EMERGENCY_REQUEST_CHARGE_ERROR = "CREATE_EMERGENCY_REQUEST_CHARGE_ERROR";

//GET EMERGENCY_REQUEST_CHARGE
export const GET_EMERGENCY_REQUEST_CHARGE_REQ = "GET_EMERGENCY_REQUEST_CHARGE_REQ";
export const GET_EMERGENCY_REQUEST_CHARGE_ERR = "GET_EMERGENCY_REQUEST_CHARGE_ERR";
export const GET_EMERGENCY_REQUEST_CHARGE_SUCC = "GET_EMERGENCY_REQUEST_CHARGE_SUCC";


//UPDATE EMERGENCY_REQUEST_CHARGE
export const PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_REQ = "PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_REQ";
export const PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_SUCC = "PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_SUCC";
export const PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_ERR = "PUT_EMERGENCY_REQUEST_CHARGE_DETAILS_ERR";

//---------------------------------------------------//

// For codHardCopyReports  [COD_HARD_COPY_REPORTS]

// CREATE COD_HARD_COPY_REPORTS
export const CREATE_COD_HARD_COPY_REPORTS_REQUEST = "CREATE_COD_HARD_COPY_REPORTS_REQUEST";
export const CREATE_COD_HARD_COPY_REPORTS_SUCCESS = "CREATE_COD_HARD_COPY_REPORTS_SUCCESS";
export const CREATE_COD_HARD_COPY_REPORTS_ERROR = "CREATE_COD_HARD_COPY_REPORTS_ERROR";

//GET COD_HARD_COPY_REPORTS
export const GET_COD_HARD_COPY_REPORTS_REQ = "GET_COD_HARD_COPY_REPORTS_REQ";
export const GET_COD_HARD_COPY_REPORTS_ERR = "GET_COD_HARD_COPY_REPORTS_ERR";
export const GET_COD_HARD_COPY_REPORTS_SUCC = "GET_COD_HARD_COPY_REPORTS_SUCC";


//UPDATE COD_HARD_COPY_REPORTS
export const PUT_COD_HARD_COPY_REPORTS_DETAILS_REQ = "PUT_COD_HARD_COPY_REPORTS_DETAILS_REQ";
export const PUT_COD_HARD_COPY_REPORTS_DETAILS_SUCC = "PUT_COD_HARD_COPY_REPORTS_DETAILS_SUCC";
export const PUT_COD_HARD_COPY_REPORTS_DETAILS_ERR = "PUT_COD_HARD_COPY_REPORTS_DETAILS_ERR";

//---------------------------------------------------//

//---------------------------------------------------//

// For customerDevliveryCharges  [CUSTOMER_DELIVERY_CHARGES]

// CREATE CUSTOMER_DELIVERY_CHARGES
export const CREATE_CUSTOMER_DELIVERY_CHARGES_REQUEST = "CREATE_CUSTOMER_DELIVERY_CHARGES_REQUEST";
export const CREATE_CUSTOMER_DELIVERY_CHARGES_SUCCESS = "CREATE_CUSTOMER_DELIVERY_CHARGES_SUCCESS";
export const CREATE_CUSTOMER_DELIVERY_CHARGES_ERROR = "CREATE_CUSTOMER_DELIVERY_CHARGES_ERROR";

//GET CUSTOMER_DELIVERY_CHARGES
export const GET_CUSTOMER_DELIVERY_CHARGES_REQ = "GET_CUSTOMER_DELIVERY_CHARGES_REQ";
export const GET_CUSTOMER_DELIVERY_CHARGES_ERR = "GET_CUSTOMER_DELIVERY_CHARGES_ERR";
export const GET_CUSTOMER_DELIVERY_CHARGES_SUCC = "GET_CUSTOMER_DELIVERY_CHARGES_SUCC";


//UPDATE CUSTOMER_DELIVERY_CHARGES
export const PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_REQ = "PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_REQ";
export const PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_SUCC = "PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_SUCC";
export const PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_ERR = "PUT_CUSTOMER_DELIVERY_CHARGES_DETAILS_ERR";

//---------------------------------------------------//