import { combineReducers } from "redux";
import users from "../reducers/reducer.users/userReducer";
import admin from "../reducers/reducer.admin/adminReducer";
import dashboard from "../reducers/reducer.dashboard/reducer.getcall";
import technician from "../reducers/reducer.technician/technicianReducer";
import area from "../reducers/reducer.area/areaReducer";
import city from "../reducers/reducer.city/cityReducer";
import category from "../reducers/reducer.category/categoryReducer";
import service from "../reducers/reducer.service/serviceReducer";
import faq from "../reducers/reducer.faq/faqReducer";
import feedback from "../reducers/reducer.feedback/feedbackReducer";
import miscellaneous from "../reducers/reducer.miscellaneous/miscellaneousReducer";
import packages from "../reducers/reducer.package/packageReducer";
import discountcoupon from "../reducers/reducer.discountcoupon/discountcouponReducer";
import timeslot from "../reducers/reducer.timeslot/timeslotReducer";
import article from "../reducers/reducer.article/articleReducer";
import order from "../reducers/reducer.orders/orderReduder";
import user_view from "../reducers/reducer.userView/userViewReducer";
import familymember from "../reducers/reducer.familymember/familymemberReducer";
import loaderReducer from "../reducers/reducer.loader/loaderReducer.js"
import book_on_call from "../reducers/reducer.callbackrequest/callbackrequestReducer";
import support from "../reducers/reducer.support/supportReducer";
import pushnotifications from "../reducers/reducer.notification/notificationReducer";
import allOrder_graph from "../reducers/reducer.ordergraph/orderGraph";
import order_reschedule from "../reducers/reducer.reschedule/rescheduleOrder";
// combining all the defined reducers in a single root reducer for the access
export const rootReducer = combineReducers({
  dashboard: dashboard,
  auth: users,
  admin:admin,
  technician:technician,
  area:area,
  city:city,
  category:category,
  service:service,
  packages:packages,
  discountcoupon:discountcoupon,
  pushnotifications:pushnotifications,
  timeslot:timeslot,
  order_reschedule :order_reschedule,
  faq:faq,
  feedback:feedback,
  article:article,
  order:order,
  support:support,
  book_on_call:book_on_call,
  userview:user_view,
  familymember:familymember,
  miscellaneous:miscellaneous,
  loaderReducer:loaderReducer,
  allOrder_graph:allOrder_graph
});
