import React from 'react'
import { Row, Col,Modal,ModalBody } from "reactstrap";
// import { CSpinner} from '@coreui/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {  useSelector } from 'react-redux';
function Loader(props) {
    const loader = useSelector((state) => state.loaderReducer);
    const {isLoading = false} =loader;
    return (
        <div className="animated fadeIn">
          { isLoading && 
        <Row>
          <Col>
          <Modal 
        //   backdrop={false}
          isOpen={isLoading} 
            // toggle={isLoading}
            centered
            size="sm" 
            className="customBg" 
            >
                <ModalBody>
                {/* <CSpinner color="primary" size="lg" className="float-center"/> */}
                <CircularProgress color="primary"/>
               
                </ModalBody>
              </Modal>

          </Col>
          </Row> 
          } 
        </div>
    )
}

export default Loader
