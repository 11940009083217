import { getAction } from "connected-react-router";
import * as pushnotificationsActionType from "../../actions/action.notification/action.type";


const pushnotifications = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getallpushnotifications_error: null,
    getallpushnotifications_status: null,

    // pushnotifications

    create_pushnotifications: null,
    send_pushnotifications: null,
    get_all_pushnotifications: [],
     all_pushnotifications: null,

     pushnotifications_enable_disable_status: null,
    
    },
    action
) =>{

    switch (action.type) {
    
        //pushnotifications reducers type
        
    
        //create new pushnotifications
        case pushnotificationsActionType.CREATE_PUSH_NOTIFICATIONS_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case pushnotificationsActionType.CREATE_PUSH_NOTIFICATIONS_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_pushnotifications: action.result,
          });
        case pushnotificationsActionType.CREATE_PUSH_NOTIFICATIONS_ERROR:
          return Object.assign({}, state, {
            getallpushnotifications_status: action.status,
            getallpushnotifications_error: action.error,
          });
         
          //send new pushnotifications
        case pushnotificationsActionType.SEND_PUSH_NOTIFICATIONS_REQUEST:
            return Object.assign({}, state, {
              status: action.status,
            });
          case pushnotificationsActionType.SEND_PUSH_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
              status: action.status,
              send_pushnotifications: action.payload,
            });
          case pushnotificationsActionType.SEND_PUSH_NOTIFICATIONS_ERROR:
            return Object.assign({}, state, {
              getallpushnotifications_status: action.status,
              getallpushnotifications_error: action.error,
            });
          // all pushnotificationss get
          case pushnotificationsActionType.GET_ALL_PUSH_NOTIFICATIONS_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case pushnotificationsActionType.GET_ALL_PUSH_NOTIFICATIONS_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_pushnotifications: action.result,
            });
          case pushnotificationsActionType.GET_ALL_PUSH_NOTIFICATIONS_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          
          // update enable disable pushnotifications status 
          case pushnotificationsActionType.PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case pushnotificationsActionType.PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              pushnotifications_enable_disable_status:action.payload,
            });
          case pushnotificationsActionType.PUT_PUSH_NOTIFICATIONS_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
            
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default pushnotifications;
    
    
    