import { getAction } from "connected-react-router";
import * as timeslotActionType from "../../actions/action.timeslots/action.type";


const timeslot = (
    //initialise the initial state
    state = {
         //common error and status
    error: null,
    status: null,
    getalltimeslot_error: null,
    getalltimeslot_status: null,

    // timeslot

    create_timeslot: null,
    get_all_timeslot: [],
     all_timeslot: null,

     timeslot_by_id: [],
     timeslot_by_id_loading: false,
     timeslot_by_id_error: null,
     timeslot_enable_disable_status: null,
     update_timeslot_details: null,
    },
    action
) =>{

    switch (action.type) {
    
        //timeslot reducers type
        
    
        //create new timeslot
        case timeslotActionType.CREATE_TIMESLOT_REQUEST:
          return Object.assign({}, state, {
            status: action.status,
          });
        case timeslotActionType.CREATE_TIMESLOT_SUCCESS:
          return Object.assign({}, state, {
            status: action.status,
            create_timeslot: action.result,
          });
        case timeslotActionType.CREATE_TIMESLOT_ERROR:
          return Object.assign({}, state, {
            getalltimeslot_status: action.status,
            getalltimeslot_error: action.error,
          });
         
          // all timeslots get
          case timeslotActionType.GET_ALL_TIMESLOT_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case timeslotActionType.GET_ALL_TIMESLOT_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_timeslot: action.result,
            });
          case timeslotActionType.GET_ALL_TIMESLOT_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
          // get timeslots by id 
          case timeslotActionType.GET_TIMESLOT_BY_ID_REQ:
          return Object.assign({}, state, {
            timeslot_by_id: [],
            timeslot_by_id_loading: true,
            timeslot_by_id_error: null,
          });
        case timeslotActionType.GET_TIMESLOT_BY_ID_SUCCESS:
          return Object.assign({}, state, {
            timeslot_by_id: action.payload,
            timeslot_by_id_loading: false,
            timeslot_by_id_error: null,
          });
        case timeslotActionType.GET_TIMESLOT_BY_ID_ERR:
          return Object.assign({}, state, {
            timeslot_by_id: [],
            timeslot_by_id_loading: false,
            timeslot_by_id_error: action.payload,
          });
          // update enable disable timeslot status 
          case timeslotActionType.PUT_TIMESLOT_STATUS_BY_ID_REQ:
            return Object.assign({}, state, {
              status: action.status,
              
            });
          case timeslotActionType.PUT_TIMESLOT_STATUS_BY_ID_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              timeslot_enable_disable_status:action.payload,
            });
          case timeslotActionType.PUT_TIMESLOT_STATUS_BY_ID_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });
    
              // UPDATE timeslot DETAILS
        case timeslotActionType.PUT_TIMESLOT_DETAILS_REQ:
          return Object.assign({}, state, {
            status: action.status,
          });
        case timeslotActionType.PUT_TIMESLOT_DETAILS_SUCC:
          return Object.assign({}, state, {
            status: action.status,
            update_timeslot_details: action.result,
          });
        case timeslotActionType.PUT_TIMESLOT_DETAILS_ERR:
          return Object.assign({}, state, {
            status: action.status,
            error: action.error,
          });
    
    
        //DEFAULT BEHAVIOR
        default:
          return state;
      }
    };
    export default timeslot;
    
    
    