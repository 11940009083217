/**
 * normalizeUser
 * Signature :: Object<ServerUser> -> { Object<ReduxUser> }
 * Takes a user object from the server & returns an object with
 * an `id` instead of an `_id` property
 **/
export const normalizeUser = ({ ...rest }) => ({
  ...rest
  // id: _id
});

export const normalizeErrorMsg = payload => {
  if (payload && payload.response && payload.response.data) {
    const { data } = payload.response
    if (data && typeof data === "string") return data
    if (data.msg && typeof data.msg === "string" && data.msg.length < 50)
      return data.msg
    return `Normalize error message failed: ${JSON.stringify(data, null, 2)}`
  }
  return `Error cannot be detected: ${JSON.stringify(
    payload.response,
    null,
    2
  )}`
}
