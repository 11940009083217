// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_ARTICLE_REQUEST = "CREATE_ARTICLE_REQUEST";
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_ERROR = "CREATE_ARTICLE_ERROR";

//GET ALL  ARTICLE
export const GET_ALL_ARTICLE_REQ = "GET_ALL_ARTICLE_REQ";
export const GET_ALL_ARTICLE_ERR = "GET_ALL_ARTICLE_ERR";
export const GET_ALL_ARTICLE_SUCC = "GET_ALL_ARTICLE_SUCC";

//get ARTICLE by id
export const GET_ARTICLE_BY_ID_REQ = "GET_ARTICLE_BY_ID_REQ";
export const GET_ARTICLE_BY_ID_ERR = "GET_ARTICLE_BY_ID_ERR";
export const GET_ARTICLE_BY_ID_SUCCESS = "GET_ARTICLE_BY_ID_SUCCESS";

// ENABLE_DISABLE ARTICLE
export const PUT_ARTICLE_STATUS_BY_ID_REQ = "PUT_ARTICLE_STATUS_BY_ID_REQ";
export const PUT_ARTICLE_STATUS_BY_ID_ERR = "PUT_ARTICLE_STATUS_BY_ID_ERR";
export const PUT_ARTICLE_STATUS_BY_ID_SUCC = "PUT_ARTICLE_STATUS_BY_ID_SUCC";


//UPDATE ARTICLE DETAILS
export const PUT_ARTICLE_DETAILS_REQ = "PUT_ARTICLE_DETAILS_REQ";
export const PUT_ARTICLE_DETAILS_SUCC = "PUT_ARTICLE_DETAILS_SUCC";
export const PUT_ARTICLE_DETAILS_ERR = "PUT_ARTICLE_DETAILS_ERR";


// //UPDATE ARTICLE DETAILS
// export const PUT_CAMP_ARTICLE_REQ = "PUT_CAMP_ARTICLE_REQ";
// export const PUT_CAMP_ARTICLE_SUCC = "PUT_CAMP_ARTICLE_SUCC";
// export const PUT_CAMP_ARTICLE_ERR = "PUT_CAMP_ARTICLE_ERR";








