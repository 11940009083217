// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";





export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_ERROR = "CREATE_ADMIN_ERROR";

//GET ALL  ADMIN
export const GET_ALL_ADMIN_REQ = "GET_ALL_ADMIN_REQ";
export const GET_ALL_ADMIN_ERR = "GET_ALL_ADMIN_ERR";
export const GET_ALL_ADMIN_SUCC = "GET_ALL_ADMIN_SUCC";

//get ADMIN by id
export const GET_ADMIN_BY_ID_REQ = "GET_ADMIN_BY_ID_REQ";
export const GET_ADMIN_BY_ID_ERR = "GET_ADMIN_BY_ID_ERR";
export const GET_ADMIN_BY_ID_SUCCESS = "GET_ADMIN_BY_ID_SUCCESS";

// ENABLE_DISABLE ADMIN
export const PUT_ADMIN_STATUS_BY_ID_REQ = "PUT_ADMIN_STATUS_BY_ID_REQ";
export const PUT_ADMIN_STATUS_BY_ID_ERR = "PUT_ADMIN_STATUS_BY_ID_ERR";
export const PUT_ADMIN_STATUS_BY_ID_SUCC = "PUT_ADMIN_STATUS_BY_ID_SUCC";


//UPDATE ADMIN DETAILS
export const PUT_ADMIN_DETAILS_REQ = "PUT_ADMIN_DETAILS_REQ";
export const PUT_ADMIN_DETAILS_SUCC = "PUT_ADMIN_DETAILS_SUCC";
export const PUT_ADMIN_DETAILS_ERR = "PUT_ADMIN_DETAILS_ERR";


// //UPDATE ADMIN DETAILS
// export const PUT_CAMP_ADMIN_REQ = "PUT_CAMP_ADMIN_REQ";
// export const PUT_CAMP_ADMIN_SUCC = "PUT_CAMP_ADMIN_SUCC";
// export const PUT_CAMP_ADMIN_ERR = "PUT_CAMP_ADMIN_ERR";








