import * as types from '../../actions/constance/action.constance.js';
const initalState ={
    isLoading:false,
   
}


export default function (state ={...initalState}, action) {
    switch(action.type){
        case types.LOADER:return {
            ...state,
            isLoading:action.payload
        };
        
        default :return state
    }
}