// /*define your type here, so that you can use it in action */

//Example

//dashboard action types
export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

//some other action types
export const CAMP_STATE_REQ = "CAMP_STATE_REQ";
export const CAMP_STATE_SUCC = "CAMP_STATE_SUCC";
export const CAMP_STATE_ERR = "CAMP_STATE_ERR";

// get 

//get_campaign_count
export const GET_CAMPAIGN_COUNT_REQ = "GET_CAMPAIGN_COUNT_REQ";
export const GET_CAMPAIGN_COUNT_ERR = "GET_CAMPAIGN_COUNT_ERR";
export const GET_CAMPAIGN_COUNT_SUCC = "GET_CAMPAIGN_COUNT_SUCC";

//get_question_review_count
export const GET_QUESTION_REVIEW_COUNT_REQ = "GET_QUESTION_REVIEW_COUNT_REQ";
export const GET_QUESTION_REVIEW_COUNT_ERR = "GET_QUESTION_REVIEW_COUNT_ERR";
export const GET_QUESTION_REVIEW_COUNT_SUCC = "GET_QUESTION_REVIEW_COUNT_SUCC";

//get_users_review_count
export const GET_USERS_REVIEW_COUNT_REQ = "GET_USERS_REVIEW_COUNT_REQ";
export const GET_USERS_REVIEW_COUNT_ERR = "GET_USERS_REVIEW_COUNT_ERR";
export const GET_USERS_REVIEW_COUNT_SUCC = "GET_USERS_REVIEW_COUNT_SUCC";





// get dashboard data
export const GET_DASHBOARD_DATA_REQ = "GET_DASHBOARD_DATA_REQ";
export const GET_DASHBOARD_DATA_ERR = "GET_DASHBOARD_DATA_ERR";
export const GET_DASHBOARD_DATA_SUCC = "GET_DASHBOARD_DATA_SUCC";

// side bar 
export const SET_SIDE_BAR_REQ = "SET_SIDE_BAR_REQ";
export const SET_SIDE_BAR = "SET_SIDE_BAR";

export const set = "set";

